import {createStore} from 'redux';
import reducers from './Reducers';
import {objectAtPath} from "@utahdts/dts-react-common";
import {dispatchField} from "./Shared";

/*
!! DO NOT USE THIS !!

This is a MobX app, but ReactCommon's OidcCore component uses Redux, so including
Redux ONLY for OidcCore interactability until it can be refactored to not use redux.

*/
const defaultState = {
    user: {
        name: undefined,
        email: undefined,
        role: undefined,
    },
    oidc: undefined,
};

export const dispatchDefaultState = paths => _.castArray(paths).forEach(path => dispatchField(path, objectAtPath(defaultState, path)));
export const getDefaultState = path => objectAtPath(defaultState, path);

const reduxStore = createStore((state, action) => {
        // === reducers ===
        let reducer = false;

        // is reducer valid?
        if (action.type in reducers) {
            reducer = reducers[action.type];
        }

        // ignore redux/react "system" reducers
        if (!reducer && action.type.indexOf('@@') !== 0) {
            console.error('unknown reducer action:', action.type, action);
        }

        // DO IT!
        return reducer ? reducer(state, action) : state;
    }, defaultState

    // for chrome redux plugin
    , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default reduxStore;
