import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Button, Table, MessagePopupCore} from "@utahdts/dts-react-common";
import appStore from "../app/mobx/appStore";
import columns from "./columns/PatentColumns"
import webservice from "../app/webservice/webservice";
import Patent from "./modals/Patent";
import security from "../app/roles/security";
import roles from "../app/roles/roles";
import {usePrevious} from "../misc/hooks/usePrevious";

const Patents = () => {
    const [isBusy, setBusy] = useState(true);
    const [patents, setPatents] = useState([]);
    const [showPatent, displayPatent] = useState(false);
    const [patent, setWac] = useState({});
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState(undefined);
    const prevOrg = usePrevious(appStore.currentOrg);

    const displayError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred. Please try again later.'
        })
    };

    const editPatent = (isNew, patent, showPatent) => {
        setIsNew(isNew);
        let orgID = security.hasAnyRole([roles.dev, roles.admin]) ? "" : appStore.currentOrg ? appStore.currentOrg.id : undefined;
        patent.org = patent.org ? patent.org : orgID;
        setWac(patent);
        displayPatent(showPatent);
    };

    const resetPatent = () => {
        editPatent(true, {}, false);
        setError(undefined);
    };

    const savePatent = patent => webservice.wac.savePatent(patent).then(() => {
        setBusy(true);
        resetPatent();
        getPatents();
    }).catch(displayError);

    const getPatents = () => {
        let orgID = security.hasAnyRole([roles.dev, roles.admin]) ? "" : appStore.currentOrg ? appStore.currentOrg.id : "not found";
        webservice.wac.patentLostPerOrg(orgID).then(data => {
            setPatents(data.patentLostByOrg);
            setBusy(false);
        }).catch(displayError);
    };

    useEffect(() => {
        if(prevOrg !== appStore.currentOrg) {
            setBusy(true);
            getPatents();
        }
    }, [appStore.currentOrg]);

    useEffect(() => {
        getPatents();
    }, []);

    return (
        <div key="listings" className="home-wrapper">
            <div className="home-content">
                <h1 className="mb-zero">Patent History</h1>
            </div>
            <div className="buttons flex">
                <Button
                    label="Manually Enter History"
                    className="mr-spacing secondary"
                    onClick={() => editPatent(true, {}, true)}
                />
            </div>
            <div>
                <Table
                    list={patents}
                    columns={columns(editPatent)}
                    filters={true}
                    showLimit={50}
                    ajaxSpinner={isBusy}
                />
            </div>
            {showPatent && <Patent
                onClose={resetPatent}
                onSave={savePatent}
                isNew={isNew}
                patent={patent}
            />}
        </div>
    )
};

export default observer(Patents);
