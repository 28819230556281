import React, {useEffect, useState, useRef} from 'react';
import {
    PopupWindow,
    Button,
    ValidationStyle,
    InputList,
    validationEngineFromInputInformationList, isEmpty
} from '@utahdts/dts-react-common';
import PropTypes from "prop-types";
import useRefLazy from "../../misc/hooks/useRefLazy";
import userInputs from "../inputs/userInputs";
import Icon from "../../misc/Icon";
import Role from "../Role";
import roles from "../../app/roles/roles";

const propTypes = {
    user: PropTypes.object.isRequired,

    isNew: PropTypes.bool.isRequired,

    orgs: PropTypes.array,

    onClose: PropTypes.func.isRequired,

    onSave: PropTypes.func.isRequired,
};
const defaultProps = {
    orgs: []
};

const User = ({user, orgs, isNew, onClose, onSave}) => {
    const [error, setError] = useState(undefined);
    const [isBusy, setBusy] = useState(false);

    const [inputValues, setInputValues] = useState(() => (Object.assign({
        id: null,
        name: null,
        email: null,
        phone: null,
        orgs: [],
        roles: [],
    }, user)));
    const inputValuesRef = useRef();
    inputValuesRef.current = inputValues;

    const [validationEngineStyle, setValidationEngineStyle] = useState(ValidationStyle.BLURRED);
    const validationEngineRef = useRef(undefined);

    const inputs = useRefLazy(() => userInputs({
        inputsOnChange: (field, value) => setInputValues(oldValues => ({ ...oldValues, [field]: value }))
    }, isNew, true));

    const [validationEngine, setValidationEngine] = useState(undefined);
    validationEngineRef.current = validationEngine;
    if (validationEngine) {
        validationEngine.validationStyle = validationEngineStyle;
    }

    const submit = () => {
        setValidationEngineStyle(ValidationStyle.ALWAYS);
        if (validationEngineRef.current && validationEngineRef.current.isValid(inputValuesRef.current) && checkRoles()) {
            setBusy(true);
            setError(undefined);
            onSave(inputValues, isNew).catch(() => setBusy(false));
        }
    };

    const checkRoles = () => {
        setError(undefined);
        let check = true;
        let cleanedUpRoles = [].concat(inputValues.roles);
        cleanedUpRoles.forEach(role => {
            if((isEmpty(role.role) && isEmpty(role.org))
                || (!isEmpty(role.role) && ![roles.admin, roles.dev].includes(role.role) && isEmpty(role.org))
                || (!isEmpty(role.org) && isEmpty(role.role))) {
                role.error = 'A valid role requires a value and a company assigned to it.';
                check = false;
            } else {
                role.error = undefined;
            }
        });
        setInputValues(oldValues => ({ ...oldValues, roles: cleanedUpRoles }));

        if(cleanedUpRoles.filter(r => isEmpty(r.error)).length === 0) {
            setError('A valid role is required.');
            check = false;
        }

        return check;
    };

    const onChange = (field, value) => setInputValues(oldValues => ({ ...oldValues, [field]: value }));

    const updateRole = (field, value, index) => {
        let items = [...inputValues.roles];
        let item = {...items[index]};
        item[field] = value;
        items[index] = item;
        setInputValues(oldValues => ({ ...oldValues, roles: items }))
    };

    const deleteRole = index => {
        let allRoles = inputValues.roles.concat([]);
        allRoles.splice(index, 1);
        setInputValues(oldValues => ({ ...oldValues, roles: allRoles }));
    };

    useEffect(() => {
        setValidationEngine(validationEngineFromInputInformationList(inputs.current));
    }, [inputs.current]);

    return (
        <PopupWindow
            className="popup-window--large"
            onCloseButtonCallback={onClose}
            footerChildren={[
                <Button label="Close" onClick={onClose} key="close-button" className="button--black"/>,
                <Button label="Save" busy={isBusy} onClick={submit} key="save-button"/>
            ]}>
            <h3>{inputValues.id ? 'Edit user' : 'Add new user'}</h3>
            <InputList inputs={Object.values(inputs.current)} data={inputValues} validationEngine={validationEngine} className="stack-form flex flex-wrap half-form" />
            <Button
                label="Add Role"
                className="mr-spacing flex align-items-center button--short mb-spacing"
                onClick={() => onChange('roles', inputValues.roles.concat([{
                    org: null,
                    role: null,
                    primaryContact: null,
                    alternativeContact: null
                }]))}
                children={<span className="icon--round ml-spacing">{Icon.iconPlus()}</span>}
                childrenPostion={Button.CHILDREN_POSITION.AFTER}
            />
            {error && <div className="form-error mb-spacing">{error}</div>}
            {inputValues.roles?.map((role, index) =>
                <Role
                    role={role}
                    orgs={orgs}
                    index={index}
                    deleteRole={deleteRole}
                    onChange={updateRole}
                    key={"role-user-" + index} />)}
        </PopupWindow>
    )
};

User.propTypes = propTypes;
User.defaultProps = defaultProps;

export default User;