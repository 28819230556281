// stores data as {[identifier]: {[criteria]: promise, [criteria]: promise}}
const runningPromises = {};

/**
 * this is a custom memoization. would've used a library but
 * it needs to reset the memoized value once the promise resolves.
 * identifier and criteria determine if func is called. When identifier
 * is not running then a new one is called. If running, then a new one
 * is called if the given criteria does not match the running criteria.
 *
 * @param func the function to call if no promise is outstanding
 * @param identifier ID grouping calls together
 * @param criteria criteria identifying when a new promise should be ran for the same identifier; note JSON.stringify will be called on this value
 * @return {*}
 */
export default (func, identifier, criteria) => {
    // check if already running
    let promises = runningPromises[identifier];

    // if new identifier, then create new context for it
    if (!promises) {
        runningPromises[identifier] = {};
        promises = runningPromises[identifier];
    }

    // make a string so it'll work as a key almost no matter what it is
    const jsonCriteria = JSON.stringify(criteria);

    // get any of the identifier's promises for this jsonCriteria
    let result = promises[jsonCriteria];

    // didn't find a currently running one, so create a new one
    if (!result) {
        result = func();
        promises[jsonCriteria] = result;
        result.then(data => {
            // this is the trick that makes this not true memoization:
            // it removes itself from the cache when done so that the next call is not cached
            delete promises[jsonCriteria];

            // let the promise pretend like we're not here and continue with the given data
            return data;
        });
    }

    return result;
};
