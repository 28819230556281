import {Components, InputInformation, ValidationType, Size, RegexLib} from "@utahdts/dts-react-common";
import Inputmask from "inputmask";

export default ({inputsOnChange}, isNew) => {
    const inputs = {
        ndc: new InputInformation({
            field: 'ndc',
            label: 'National Drug Code:',
            type: Components.TEXT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
            maskType: {
                inputMask: new Inputmask('99999999999', {
                    placeholder: '',
                    clearMaskOnLostFocus: false,
                    autoUnmask: true,
                    onBeforePaste: value => {
                        value.match(/\d+/g) && inputsOnChange('ndc', value.match(/\d+/g).join(''));
                    }
                }),
            },
        }),
        type: new InputInformation({
            field: 'type',
            label: 'Type:',
            type: Components.SELECT_INPUT,
            multiSelect: false,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
            options: [
                    {value: 'brand', label: 'Brand'},
                    {value: 'generic', label: 'Generic'}
            ]
        }),
        description: new InputInformation({
            field: 'description',
            label: 'Description:',
            type: Components.TEXT_AREA,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
    };

    Object.values(inputs).forEach(input => input.onChange = inputsOnChange);

    return inputs;
}