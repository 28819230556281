import "core-js/stable";
import React, {useEffect} from 'react';
import {render} from 'react-dom';
import {BrowserRouter, withRouter} from 'react-router-dom';
import AppRoutes from './pages/routes/AppRoutes';
import "../../css/index.scss";
import {MessagePopupCore, OIDCOptions} from '@utahdts/dts-react-common';
import historyStore from './mobx/historyStore';
import UtahHeader from "./UtahHeader/UtahHeader";
import 'mobx-react-lite/batchingForReactDom';
import HeadTags from "./HeadTags";
import PropTypes from "prop-types";
import {Provider} from "react-redux";
import ReduxToMobXShim from "./redux/ReduxToMobXShim";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import reduxStore from "./redux/ReduxStore";
import CompanyPopup from "../misc/CompanyPopup";

const propTypes = {
    history: PropTypes.object.isRequired,
};
const defaultProps = {};

const AppFunc = ({history}) => {
    useEffect(() => {
        if (!history) {
            console.error("history does not exist for app");
        }
        historyStore.history = history;
    }, []);

    return (
        <ReduxToMobXShim>
            <HeadTags/>
            <UtahHeader/>
            <main className="main-content-wrapper">
                <section className="section-content">
                    <AppRoutes/>
                </section>
            </main>
            <MessagePopupCore/>
            <CompanyPopup/>
        </ReduxToMobXShim>
    );
};

AppFunc.propTypes = propTypes;
AppFunc.defaultProps = defaultProps;

const App = withRouter(AppFunc);

OIDCOptions.client_id = 'eosinlike-Alpert-076363';
OIDCOptions.responseType = 'code';
OIDCOptions.oidcSecuredPaths = [''];
OIDCOptions.authorityUrls = {
    local: 'https://login.dts.utah.gov',
    dev: 'https://login.dts.utah.gov',
    at: 'https://login.dts.utah.gov',
    prod: 'https://login.dts.utah.gov',
};

const div = document.createElement('div');
document.body.appendChild(div);

render(<BrowserRouter basename={'/'}><DndProvider backend={HTML5Backend}><Provider store={reduxStore}><App/></Provider></DndProvider></BrowserRouter>, div);
