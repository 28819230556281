import {configure, makeAutoObservable} from "mobx";
import LocalStorage from "../localStorage/LocalStorage";
import jsonwebtoken from "jsonwebtoken";

const verifyOidcUser = oidcUser => {
    let decoded = oidcUser?.access_token && jsonwebtoken.decode(oidcUser?.access_token);
    if (decoded && decoded.exp * 1000 <= Date.now()) {
        oidcUser = null;
    }
    return oidcUser;
};

configure({
    enforceActions: "never",
});

export default new class {
    // the current page title
    pageTitle = '';

    // how many outstanding ajax requests are running
    isAjaxing = 0;
    // the user from the webservice from jwt
    loggedInUser = null;
    // user from oidc service
    oidcUser = null;

    currentDrug = undefined;

    currentOrg = undefined;

    userOrgs = [];

    showMessage = false;

    constructor() {
        makeAutoObservable(this);
        const loggedInUser = LocalStorage.loggedInUser.get();
        const oidcUser = verifyOidcUser(LocalStorage.oidcUser.get());
        if (loggedInUser && oidcUser) {
            // still load user from webservice in case roles or something changes, but start with a default
            loggedInUser.loadedFromStorage = true;
            this.loggedInUser = loggedInUser;
            this.oidcUser = oidcUser;
        }
    }

    signOut() {
        this.loggedInUser = null;
        this.oidcUser = null;
        LocalStorage.oidcUser.set(null);
        LocalStorage.loggedInUser.set(null);
    }

}();
