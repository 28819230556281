import React from "react";
import Users from "../../pages/Users";
import Home from "../../pages/Home";
import {Route} from "react-router-dom";
import Page from "./Page";
import security from "../roles/security";
import roles from "../roles/roles";
import Listings from "../../pages/Listings";
import Wacs from "../../pages/Wacs";
import Companies from "../../pages/Companies";
import SingleCompany from "../../pages/SingleCompany";
import Patents from "../../pages/Patents";
import Approvals from "../../pages/Approvals";
import Report from "../../pages/Report";

const requireAdminRole = () => security.hasAnyRole([roles.dev, roles.admin]);

const Pages = {
    users: new Page({
        component: Users,
        path: '/users',
        title: 'Users',
        anyRoles: [roles.admin, roles.dev, roles.orgAdmin]
    }),
    listings: new Page({
       component: Listings,
       path: '/listings',
       title: 'Drug Listings',
       anyRoles: [roles.admin, roles.dev, roles.orgAdmin, roles.user]
    }),
    wacs: new Page({
        component: Wacs,
        path: '/wacs',
        title: 'WAC History'
    }),
    patents: new Page({
        component: Patents,
        path: '/patents',
        title: 'Patent History'
    }),
    approvals: new Page({
        component: Approvals,
        path: '/approvals',
        title: 'Approval History'
    }),
    companies: new Page({
        component: Companies,
        path: '/companies',
        title: 'Companies',
        anyRoles: [roles.admin, roles.dev]
    }),
    singleCompany: new Page({
        component: SingleCompany,
        path: '/company',
        title: 'Company Info',
        anyRoles: [roles.orgAdmin]
    }),
    report: new Page({
        component: Report,
        path: '/report',
        title: 'Report',
        anyRoles: [roles.admin, roles.dev]
    }),
    home: new Page({
        component: Home,
        path: '/',
        title: 'Home',
    })
};

export default Pages;
export const renderPageRoutes = pages => Object.values(pages).map(page => <Route key={page.path} path={page.path} component={page.render}/>);
