import React from 'react';
import {joinClassNames} from '@utahdts/dts-react-common';

export default {
    iconArrowLeft: className => <svg viewBox="0 0 1792 1792" className={joinClassNames('svg-icon', 'svg-left', className)} role="presentation"><path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"/></svg>,

    iconEllipsis: className => <svg viewBox="0 0 1792 1792" className={joinClassNames('svg-icon', 'svg-ellipsis', className)} role="presentation"><path d="M1088 1248v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68zm0-512v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68zm0-512v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68z"/></svg>,

    iconEye: className => <svg viewBox="0 0 56 30" className={joinClassNames('svg-icon', 'svg-eye', className)} role="presentation"><path d="M28,15c2.762,0,5-2.238,5-5s-2.238-5-5-5c-2.761,0-5,2.238-5,5S25.239,15,28,15z M39.807,2.499
			c-0.002-0.003-0.003-0.007-0.005-0.009c-0.086-0.034-0.175-0.062-0.262-0.095C35.808,0.953,31.818,0,28,0
			s-7.807,0.953-11.54,2.395c-0.086,0.033-0.175,0.062-0.262,0.096c-0.002,0.003-0.003,0.006-0.005,0.009C7.337,5.987,0,12.203,0,15
			c0,4,15,15,28,15s28-11,28-15C56,12.203,48.663,5.987,39.807,2.499z M17.58,4.064C20.949,2.822,24.542,2,28,2
			c3.458,0,7.051,0.822,10.42,2.064C39.423,5.813,40,7.838,40,10c0,6.627-5.372,12-12,12c-6.627,0-12-5.373-12-12
			C16,7.838,16.577,5.813,17.58,4.064z M28,28C16,28,2.188,18,2.188,15c0-1.908,5.564-6.647,12.719-9.845
			C14.342,6.668,14,8.289,14,10c0,7.732,6.268,14,14,14c7.732,0,14-6.268,14-14c0-1.711-0.342-3.332-0.906-4.845
			C48.248,8.353,53.812,13.092,53.812,15C53.812,18,40,28,28,28z"/></svg>,

    iconGraph: className => <svg viewBox="0 0 56 44" className={joinClassNames('svg-icon', 'svg-graph', className)} role="presentation"><path d="M54,0H2C0.896,0,0,0.896,0,2v40c0,1.104,0.896,2,2,2h52c1.104,0,2-0.896,2-2V2C56,0.896,55.104,0,54,0z M54,42H2V2h52V42z
			 M7,28c0.327,0,0.604-0.168,0.786-0.41L7.8,27.6l4.879-6.505l5.378,15.238l0.01-0.003C18.204,36.718,18.564,37,19,37
			c0.391,0,0.721-0.23,0.886-0.558l0.009,0.005l9.928-19.854l4.959-4.96l7.351,12.863l0.014-0.008C42.318,24.789,42.629,25,43,25
			c0.436,0,0.796-0.282,0.934-0.671l0.01,0.004l6-17l-0.01-0.004C49.971,7.226,50,7.117,50,7c0-0.552-0.447-1-1-1
			c-0.436,0-0.796,0.282-0.934,0.671l-0.01-0.004l-5.272,14.939L35.868,9.504l-0.014,0.008C35.682,9.212,35.371,9,35,9
			c-0.276,0-0.526,0.112-0.707,0.293l-6,6l0.01,0.01c-0.074,0.075-0.14,0.159-0.188,0.255l-0.009-0.005l-8.946,17.894l-5.216-14.779
			l-0.01,0.004C13.796,18.282,13.436,18,13,18c-0.327,0-0.604,0.167-0.786,0.41L12.2,18.4l-6,8l0.014,0.01
			C6.088,26.577,6,26.774,6,27C6,27.553,6.447,28,7,28z"/></svg>,

    iconDownload: className => <svg viewBox="0 0 56 56" className={joinClassNames('svg-icon', 'svg-download', className)} role="presentation"><path d="M20,22c-0.553,0-1,0.448-1,1c0,0.276,0.112,0.526,0.293,0.707l8,8C27.474,31.888,27.724,32,28,32s0.526-0.112,0.707-0.293
			l8-8C36.888,23.526,37,23.276,37,23c0-0.552-0.447-1-1-1c-0.276,0-0.526,0.112-0.707,0.293L29,28.586V1c0-0.552-0.447-1-1-1
			c-0.552,0-1,0.448-1,1v27.586l-6.293-6.293C20.526,22.112,20.276,22,20,22z M55.926,34.63l0.003-0.001l-0.007-0.018l-0.001-0.001
			L43.929,4.629L43.926,4.63C43.777,4.262,43.421,4,43,4h-9c-0.553,0-1,0.447-1,1s0.447,1,1,1h8.323l11.2,28H41c-5,0-6,8-8,8
			s-5,0-5,0s-3,0-5,0s-3-8-8-8H2.477l11.2-28H22c0.553,0,1-0.447,1-1s-0.447-1-1-1h-9c-0.421,0-0.777,0.262-0.925,0.63l-0.004-0.001
			L0.079,34.61l-0.001,0.001l-0.007,0.018l0.003,0.001C0.028,34.745,0,34.869,0,35v20c0,0.553,0.447,1,1,1h54c0.553,0,1-0.447,1-1
			V35C56,34.869,55.972,34.745,55.926,34.63z M54,54H2V36c2.628,0,9.769,0,13,0c4,0,4,8,8,8h5h5c4,0,4-8,8-8c3.231,0,10.372,0,13,0
			V54z"/></svg>,

    iconUpload: className => <svg viewBox="0 0 42 56" className={joinClassNames('svg-icon', 'svg-download', className)} role="presentation"><path d="M13,10c0.276,0,0.526-0.112,0.707-0.293L20,3.414V35c0,0.553,0.447,1,1,1s1-0.447,1-1V3.414l6.293,6.293
			C28.474,9.888,28.724,10,29,10c0.553,0,1-0.447,1-1c0-0.276-0.112-0.526-0.293-0.707l-8-8C21.526,0.112,21.276,0,21,0
			s-0.526,0.112-0.707,0.293l-8,8C12.112,8.474,12,8.724,12,9C12,9.553,12.447,10,13,10z M40,16H27c-0.553,0-1,0.447-1,1
			s0.447,1,1,1h13v36H2V18h13c0.553,0,1-0.447,1-1s-0.447-1-1-1H2c-1.104,0-2,0.896-2,2v36c0,1.104,0.896,2,2,2h38
			c1.104,0,2-0.896,2-2V18C42,16.896,41.104,16,40,16z"/></svg>,

    iconUsers: className => <svg viewBox="0 0 56.003 36.059" className={joinClassNames('svg-icon', 'svg-users', className)} role="presentation"><path d="M2.001,27.439c0-0.883-0.25-2.719,4.368-4.26c1.438-0.618,2.261-1.483,2.526-2.603c0.438-1.843-0.635-3.516-0.788-3.7
			c-0.103-0.113-1.101-1.447-1.101-4.573c0-3.829,0.912-5.253,3.95-5.253c3.04,0,4.045,1.424,4.045,5.253
			c0,3.126-1.091,4.46-1.143,4.516c-0.204,0.242-1.153,1.915-0.715,3.758c0.253,1.065,1.026,1.892,2.27,2.477
			c0.894-0.335,1.832-0.673,2.837-1.017c-0.564-0.271-1.211-0.532-1.957-0.777c-0.66-0.29-1.095-0.688-1.205-1.153
			c-0.18-0.757,0.076-1.72,0.255-1.937c0.015-0.009,1.658-1.841,1.658-5.866c0-1.799,0.124-7.272-6.045-7.272
			c-6.168,0-5.949,5.474-5.949,7.272c0,4.025,1.55,5.857,1.624,5.934c0.172,0.221,0.496,1.14,0.318,1.874
			c-0.113,0.463-0.547,0.86-1.289,1.182c-5.91,1.969-5.66,5.004-5.66,6.146v2.088c0,0.995,0.781,1.531,1.518,1.531h2.603
			c0.154-0.698,0.474-1.368,0.97-2.019h-3.09V27.439z M35.348,23.453c-1.244-0.543-2.104-1.245-2.309-2.088
			c-0.377-1.546,1.122-3.267,1.114-3.267c0.12-0.121,2.875-1.989,2.875-7.766C37.029,5.897,35.87,0,28.001,0
			c-7.867,0-9.027,5.897-9.027,10.333c0,5.792,2.769,7.659,2.812,7.697c0.015,0.018,1.559,1.766,1.177,3.335
			c-0.205,0.843-1.064,1.545-2.309,2.088c-9.177,2.829-14.653,6.086-14.653,8.717v2.111c0,1.154,0.916,1.777,1.777,1.777h40.445
			c0.861,0,1.777-0.623,1.777-1.777V32.17C50.001,29.539,44.525,26.282,35.348,23.453z M48.001,34.059l-40,0.001v-1.888
			c0.033-0.552,2.501-3.503,13.294-6.824l0.101-0.036c1.888-0.81,3.153-1.982,3.514-3.485c0.612-2.551-1.499-4.972-1.639-5.135
			c-0.021-0.021-2.297-1.46-2.297-6.358C20.974,4.414,23.187,2,28.001,2s7.027,2.414,7.027,8.333c0,4.898-2.275,6.337-2.351,6.419
			c-0.085,0.103-2.197,2.523-1.585,5.074c0.36,1.503,1.626,2.676,3.515,3.485l0.1,0.036c10.794,3.321,13.262,6.272,13.294,6.822
			V34.059z M50.341,21.293c-0.742-0.321-1.176-0.719-1.289-1.182c-0.178-0.734,0.146-1.653,0.318-1.874
			c0.074-0.076,1.625-1.908,1.625-5.934c0-1.799,0.219-7.272-5.95-7.272s-6.044,5.474-6.044,7.272c0,4.025,1.644,5.857,1.657,5.866
			c0.179,0.217,0.435,1.18,0.255,1.937c-0.11,0.465-0.544,0.863-1.205,1.153c-0.746,0.245-1.393,0.507-1.957,0.777
			c1.005,0.344,1.943,0.682,2.837,1.017c1.243-0.585,2.017-1.411,2.27-2.477c0.438-1.843-0.51-3.516-0.714-3.758
			c-0.053-0.056-1.143-1.39-1.143-4.516c0-3.829,1.004-5.253,4.044-5.253c3.038,0,3.95,1.424,3.95,5.253
			c0,3.126-0.997,4.46-1.101,4.573c-0.153,0.185-1.227,1.857-0.788,3.7c0.266,1.119,1.089,1.984,2.526,2.603
			c4.618,1.541,4.368,3.377,4.368,4.26v1.601h-3.09c0.496,0.65,0.815,1.32,0.97,2.019h2.603c0.736,0,1.518-0.536,1.518-1.531v-2.088
			C56.001,26.297,56.251,23.262,50.341,21.293z"/></svg>,

    iconCards: className => <svg viewBox="0 0 56 40" className={joinClassNames('svg-icon', 'svg-cards', className)} role="presentation"><path d="M50,18H34v2h16V18z M50,14H34v2h16V14z M54,6c0-1.104-0.896-2-2-2H6C4.896,4,4,4.896,4,6v28c0,1.104,0.896,2,2,2v-2V6h46
			H54z M50,2c0-1.104-0.896-2-2-2H2C0.896,0,0,0.896,0,2v28c0,1.104,0.896,2,2,2v-2V2h46H50z M26,22H14v2h12V22z M30,18H14v2h16V18z
			 M54,8H10c-1.104,0-2,0.896-2,2v28c0,1.104,0.896,2,2,2h44c1.104,0,2-0.896,2-2V10C56,8.896,55.104,8,54,8z M54,38H43v-2h2
			c0.553,0,1-0.447,1-1c0-0.552-0.447-1-1-1h-6c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h2v2H23v-2h2c0.553,0,1-0.447,1-1
			c0-0.552-0.447-1-1-1h-6c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h2v2H10V10h44V38z M46,22H34v2h12V22z M30,14H14v2h16V14z"/></svg>,

    iconSuitcase: className => <svg viewBox="0 0 56 48" className={joinClassNames('svg-icon', 'svg-suitcase', className)} role="presentation"><path d="M52,8H40V4c0-2.209-1.791-4-4-4H20c-2.209,0-4,1.791-4,4v4H4c-2.209,0-4,1.791-4,4v32c0,2.209,1.791,4,4,4h48
			c2.209,0,4-1.791,4-4V12C56,9.791,54.209,8,52,8z M18,4c0-1.104,0.896-2,2-2h16c1.104,0,2,0.896,2,2v4H18V4z M8,46H4
			c-1.104,0-2-0.896-2-2V12c0-1.104,0.896-2,2-2h4V46z M46,46H10V10h36V46z M54,44c0,1.104-0.896,2-2,2h-4V10h4c1.104,0,2,0.896,2,2
			V44z"/></svg>,

    iconPencil: className => <svg viewBox="0 0 56.006 56.037" className={joinClassNames('svg-icon', 'svg-pencil', className)} role="presentation"><path d="M45,22.037c-0.553,0-1,0.448-1,1v31H2v-42h31c0.553,0,1-0.447,1-1c0-0.552-0.447-1-1-1H2c-1.104,0-2,0.896-2,2v42
			c0,1.104,0.896,2,2,2h42c1.104,0,2-0.896,2-2v-31C46,22.485,45.553,22.037,45,22.037z M55.414,3.451l-2.856-2.859
			c-0.789-0.789-2.067-0.789-2.856,0L20.423,29.895l-1.429,7.148l7.141-1.43L52.558,9.169L51.13,7.74L25.184,33.707l-2.619-0.238
			l-0.237-2.621L48.273,4.881l-1.429-1.43l1.429-1.43l5.712,5.719l1.429-1.43C56.203,5.521,56.203,4.241,55.414,3.451z"/></svg>,

    iconTrash: className => <svg viewBox="0 0 42 56" className={joinClassNames('svg-icon', 'svg-trash', className)} role="presentation"><path d="M11.952,12.001c-0.551,0.032-0.972,0.507-0.94,1.059l1.99,36.001c0.032,0.553,0.505,0.974,1.057,0.941
			s0.972-0.506,0.94-1.059l-1.99-36.001C12.976,12.391,12.503,11.969,11.952,12.001z M21,12c-0.552,0-1,0.448-1,1v36
			c0,0.553,0.448,1,1,1c0.553,0,1-0.447,1-1V13C22,12.448,21.553,12,21,12z M41,6H30V4c0-2.209-1.791-4-4-4H16c-2.209,0-4,1.791-4,4
			v2H1C0.448,6,0,6.448,0,7c0,0.553,0.448,1,1,1h1l4,44c0,2.209,1.791,4,4,4h22c2.209,0,4-1.791,4-4l4-44h1c0.553,0,1-0.447,1-1
			C42,6.448,41.553,6,41,6z M14,4c0-1.104,0.896-2,2-2h10c1.104,0,2,0.896,2,2v2H14V4z M34,52c0,1.104-0.896,2-2,2H10
			c-1.104,0-2-0.896-2-2L4,8h8h2h14h2h8L34,52z M28.991,13.004l-1.989,35.941c-0.032,0.552,0.388,1.024,0.939,1.057
			s1.024-0.389,1.057-0.939l1.99-35.942c0.032-0.551-0.389-1.024-0.939-1.057C29.497,12.031,29.024,12.452,28.991,13.004z"/></svg>,

    iconBan: className => <svg viewBox="0 0 1792 1792" className={joinClassNames('svg-icon', 'svg-ban', className)} role="presentation"><path d="M1440 893q0-161-87-295l-754 753q137 89 297 89 111 0 211.5-43.5t173.5-116.5 116-174.5 43-212.5zm-999 299l755-754q-135-91-300-91-148 0-273 73t-198 199-73 274q0 162 89 299zm1223-299q0 157-61 300t-163.5 246-245 164-298.5 61-298.5-61-245-164-163.5-246-61-300 61-299.5 163.5-245.5 245-164 298.5-61 298.5 61 245 164 163.5 245.5 61 299.5z"/></svg>,


    iconPlus: className => <svg viewBox="0 0 1792 1792" className={joinClassNames('svg-icon', 'svg-plus', className)} role="presentation"><path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"/></svg>,

    iconCheckbox: className => <svg viewBox="0 0 56 56" className={joinClassNames('svg-icon', 'svg-checkbox', className)} role="presentation"><path d="M47,19c-0.553,0-1,0.447-1,1v30c0,2.209-1.791,4-4,4H6c-2.209,0-4-1.791-4-4V14c0-2.209,1.791-4,4-4h34
			c0.553,0,1-0.447,1-1c0-0.552-0.447-1-1-1H6c-3.313,0-6,2.687-6,6v36c0,3.313,2.687,6,6,6h36c3.313,0,6-2.687,6-6V20
			C48,19.447,47.553,19,47,19z M56,1c0-0.552-0.447-1-1-1c-0.312,0-0.58,0.152-0.764,0.377l-0.011-0.009L23.925,37.511
			L10.707,24.293C10.526,24.112,10.276,24,10,24c-0.553,0-1,0.447-1,1c0,0.276,0.112,0.526,0.293,0.707l14,14
			C23.474,39.889,23.724,40,24,40c0.312,0,0.58-0.152,0.764-0.377l0.011,0.009l31.001-38l-0.012-0.009C55.904,1.451,56,1.24,56,1z"
    /></svg>,

    iconPill: className => <svg viewBox="0 0 56 24" className={joinClassNames('svg-icon', 'svg-pill', className)} role="presentation"><path d="M44,0H12C5.373,0,0,5.373,0,12c0,6.628,5.373,12,12,12h32c6.627,0,12-5.372,12-12C56,5.373,50.627,0,44,0z M7,13
			c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3S8.657,13,7,13z M13,8c-1.104,0-2-0.896-2-2s0.896-2,2-2s2,0.896,2,2S14.104,8,13,8
			z M44,22H29V2h15c5.522,0,10,4.478,10,10C54,17.523,49.522,22,44,22z"/></svg>,

    iconStar: className => <svg viewBox="0 0 56 52" className={joinClassNames('svg-icon', 'svg-star', className)} role="presentation"><path d="M56,20H34.625L28,0l-6.625,20H0l17.25,12.25L11,52l17-11.5L45,52l-6.25-19.75L56,20z M41.224,47.25L28,38.188
			L14.599,47.25l5.009-15.734L6.125,22h16.683L28,6l5.192,16h16.683l-13.482,9.516L41.224,47.25z"/></svg>
};
