import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Button, PopupWindow, Table, MessagePopupCore} from "@utahdts/dts-react-common";
import Icon from "../misc/Icon";
import appStore from "../app/mobx/appStore";
import columns from "./columns/UserColumns"
import webservice from "../app/webservice/webservice";
import User from "./modals/User";
import security from "../app/roles/security";
import roles from "../app/roles/roles";
import Pages from "../app/pages/Pages";
import {usePrevious} from "../misc/hooks/usePrevious";

const Users = () => {
    const [isBusy, setBusy] = useState(true);
    const [users, setUsers] = useState([]);
    const [showUser, displayUser] = useState(false);
    const [showDelete, displayDelete] = useState(false);
    const [user, setUser] = useState({});
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState(undefined);
    const [orgs, setOrgs] = useState([]);
    const prevOrg = usePrevious(appStore.currentOrg);

    const displayError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred. Please try again later.'
        })
    };

    const editUser = (isNew, user, showUser) => {
        setBusy(true);
        setIsNew(isNew);
        setUser(user);
        if(security.hasAnyRole([roles.dev, roles.admin]) && showUser){
            webservice.company.all().then(data => {
                let orgs = data.orgs.map(org =>{
                    let option = {...org};
                    option.value = org.id;
                    option.label = org.name || org.fein;
                    return option;
                });
                setOrgs(orgs);
                setBusy(false);
                displayUser(showUser);
            })
        } else if(appStore.currentOrg && showUser) {
            setOrgs([appStore.currentOrg]);
            setBusy(false);
            displayUser(showUser);
        } else {
            setBusy(false);
            displayUser(showUser);
        }
    };

    const deleteUser = user => {
        setUser(user);
        displayDelete(true);
    };

    const resetUser = () => {
        editUser(true, {}, false);
        setError(undefined);
        displayDelete(false);
    };

    const saveUser = (user, isNew) => {
        return webservice.user.perEmail(user.email).then(data => {
            let duplicate = data?.userByEmail;
            if(!!duplicate && isNew){
                let roles = duplicate.roles.concat(user.roles);
                let combined = Object.assign(user, duplicate);
                combined.roles = roles;
                user = combined;
            }

            user.orgs = user.roles ? [...new Set(user.roles.map(role => role.org))] : [];
            webservice.user.save(user).then(() => {
                setBusy(true);
                resetUser();
                getUsers();
            }).catch(displayError);
        });
    };

    const actualDelete = () => webservice.user.delete(user).then(() => {
        setBusy(true);
        resetUser();
        getUsers();
    }).catch(displayError);

    const getUsers = () => {
        let orgID = appStore.currentOrg?.id || (security.hasAnyRole([roles.dev, roles.admin]) ?  null : "not found");
        if(orgID) {
            webservice.user.perOrg(orgID).then(data => {
                setUsers(data.usersByOrg);
                setBusy(false);
            }).catch(displayError);
        } else {
            webservice.user.all().then(data => {
                setUsers(data.users);
                setBusy(false);
            }).catch(displayError);
        }
    };

    useEffect(() => {
        if(prevOrg !== appStore.currentOrg) {
            setBusy(true);
            security.hasAnyRole([roles.dev, roles.admin, roles.orgAdmin]) ? getUsers() : Pages.home.forward();
        }
    }, [appStore.currentOrg]);

    useEffect(() => {
        if(appStore.currentOrg?.id || (security.hasAnyRole([roles.dev, roles.admin]))) {
            getUsers();
        } else {
            setBusy(false);
        }
    }, []);

    return (
        <div key="listings" className="home-wrapper">
            {(appStore.currentOrg && security.hasAnyRole([roles.dev, roles.admin])) && <div>
                <Button
                    onClick={() => {
                        appStore.currentOrg = undefined;
                        Pages.companies.forward();
                    }}
                    label="Back to companies"
                    className="button--naked flex align-items-center p-none"
                    children={Icon.iconArrowLeft('blue mr-spacing--small')}
                    childrenPosition={Button.CHILDREN_POSITION.BEFORE}/>
            </div>}
            <div className="home-content">
                <h1 className="mb-zero">{appStore.currentOrg?.name || "Users"}</h1>
            </div>
            <div className="buttons flex">
                <Button
                    label="Add User"
                    className="mr-spacing flex align-items-center"
                    onClick={() => editUser(true, {}, true)}
                    children={<span className="icon--round ml-spacing">{Icon.iconPlus()}</span>}
                    childrenPostion={Button.CHILDREN_POSITION.AFTER}
                />
            </div>
            <div>
                <Table
                    list={users}
                    columns={columns(editUser, deleteUser)}
                    filters={true}
                    showLimit={50}
                    ajaxSpinner={isBusy}
                />
            </div>
            {showUser && <User
                onClose={resetUser}
                onSave={saveUser}
                isNew={isNew}
                user={user}
                orgs={orgs}
            />}
            {showDelete && <PopupWindow
                className="popup-window--large"
                onCloseButtonCallback={resetUser}
                footerChildren={[
                    <Button label="Cancel" onClick={resetUser} key="close-button" className="button--black"/>,
                    <Button label="Delete" busy={isBusy} onClick={actualDelete} key="save-button"/>
                ]}>
                <h3>Are you sure?</h3>
                <p>You are about to delete a user.</p>
            </PopupWindow>}
        </div>
    )
};

export default observer(Users);
