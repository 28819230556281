import {AjaxStatusCore, GraphQLCore, MessagePopupCore, objectAtPath} from "@utahdts/dts-react-common";
import webserviceContext from "./webserviceContext";
import appStore from "../mobx/appStore";

export const ajaxStatus = new AjaxStatusCore((id, isStarting) => appStore.isAjaxing = (appStore.isAjaxing || 0) + (isStarting ? 1 : -1));

export default new GraphQLCore({
    graphQLUrl: webserviceContext,
    ajaxStatusCore: ajaxStatus,
    loadDefaultsCallback: defaults => {
        if (appStore.oidcUser) {
            defaults.headers.common['Authorization'] = appStore.oidcUser ? `Bearer ${appStore.oidcUser.access_token}` : false;
        }
    },
    rawPromiseCallback: promise => promise.then(data => {
        const errors = objectAtPath(data, 'data.errors');
        if (errors) {
            const error = errors[0].message;
            if (error === 'Internal server error') {
                MessagePopupCore.addMessage({
                    title: 'Signed Out',
                    message: 'You have been signed out for inactivity. Please sign in and try again.'
                });
            } else {
                MessagePopupCore.addMessage({
                    title: 'Webservice Communication Interruption',
                    message: 'There was an error communicating with the webservice.',
                    subMessage: error
                });
            }
            throw error;
        }
        return data;
    }),
});
