export const Contexts = {
    AT: 'at',
    DEV: 'dev',
    LOCAL: 'local',
    PROD: 'prod',
};

export default () => {
    // see https://docs.google.com/spreadsheets/d/1YuOj5tdDo3d4KwU_k6040AEr3fKnEIYfIfsRzPrq9Hc for local
    // vhost urls.
    //
    const hostName = document.location.hostname;
    let result;

    // local urls will have the word local in them so `localhost` and `local` will both match 'local'
    if (hostName.includes('local')) {
        result = Contexts.LOCAL;

    } else if (hostName.match(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/)) {
        result = Contexts.DEV;

    } else if (hostName.includes('.dev.')) {
        result = Contexts.DEV;

    } else if (hostName.includes('.at.')) {
        result = Contexts.AT;

    } else {
        // default to prod if find no other match
        result = Contexts.PROD;
    }

    return result;
}
