import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ProtectedRoutes from "./ProtectedRoutes";
import Registration from "../../../pages/Registration";

const propTypes = {};
const defaultProps = {};

const AppRoutes = () => (
    <Switch>
        <Route path={`/registration`} render={props => <Registration/>} />
        <Route path={`/`} render={props => <ProtectedRoutes {...props}/>}/>
    </Switch>
);

AppRoutes.propTypes = propTypes;
AppRoutes.defaultProps = defaultProps;

export default AppRoutes;
