import graphQLWebservice from "./graphQLWebservice";
import webserviceAjaxIds from "./webserviceAjaxIds";
import loggedInUserQuery from "./graphql/query/loggedInUserQuery";
import oneAtATime from "./oneAtATime";
import listingsQuery from "./graphql/query/listingsQuery";
import saveDrugMutation from "./graphql/mutation/saveDrugMutation";
import wacsQuery from "./graphql/query/wacsQuery";
import saveWacMutation from "./graphql/mutation/saveWacMutation";
import wacsByNDC from "./graphql/query/wacsByNDC";
import usersQuery from "./graphql/query/usersQuery";
import saveUser from "./graphql/mutation/saveUserMutation";
import deleteUserMutation from "./graphql/mutation/deleteUserMutation";
import companiesQuery from "./graphql/query/companiesQuery";
import saveCompanyMutation from "./graphql/mutation/saveCompanyMutation";
import deleteCompanyMutation from "./graphql/mutation/deleteCompanyMutation";
import companySingleQuery from "./graphql/query/companySingleQuery";
import userSingleQuery from "./graphql/query/userSingleQuery";
import savePatentMutation from "./graphql/mutation/savePatentMutation";
import saveApprovalMutation from "./graphql/mutation/saveApprovalMutation";
import userPerEmail from "./graphql/query/userPerEmail";
import drugApprovalPerOrg from "./graphql/query/drugApprovalPerOrg";
import patentLostPerOrg from "./graphql/query/patentLostPerOrg";
import wacsPerOrg from "./graphql/query/wacsPerOrg";
import usersPerOrg from "./graphql/query/usersPerOrg";
import approveCompany from "./graphql/mutation/approveCompanyMutation";
import submitCompanyMutation from "./graphql/mutation/submitCompanyMutation";
import submitUserMutation from "./graphql/mutation/submitUserMutation";
import inactivateCompanyMutation from "./graphql/mutation/inactivateCompanyMutation";

export default {
    user: {
        loggedInUser: () => (
            oneAtATime(() => (
                    graphQLWebservice.query(loggedInUserQuery(), webserviceAjaxIds.user.loggedInUser)
                        .then(result => result.data.loggedInUser)
                ),
                webserviceAjaxIds.user.loggedInUser,
                'loggedInUser'
            )
        ),
        get: id => graphQLWebservice.query((userSingleQuery(id))).then(result => result.data),
        perEmail: email => graphQLWebservice.query((userPerEmail(email))).then(result => result.data),
        all: orgId => graphQLWebservice.query(usersQuery(orgId)).then(result => result.data),
        perOrg: orgId => graphQLWebservice.query(usersPerOrg(orgId)).then(result => result.data),
        save: user => graphQLWebservice.mutation(saveUser(user)).then(result => result.data),
        delete: user => graphQLWebservice.mutation(deleteUserMutation(user)).then(result => result.data),
        submit: user => graphQLWebservice.mutation(submitUserMutation(user)).then(result => result.data),
    },
    drug: {
        all: orgId => graphQLWebservice.query(listingsQuery(orgId)).then(result => result.data),
        save: drug => graphQLWebservice.mutation(saveDrugMutation(drug)).then(result => result.data),
    },
    wac: {
        all: () => graphQLWebservice.query(wacsQuery()).then(result => result.data),
        perDrug: ndc => graphQLWebservice.query(wacsByNDC(ndc)).then(result => result.data),
        perOrg: org => graphQLWebservice.query(wacsPerOrg(org)).then(result => result.data),
        save: wac => graphQLWebservice.mutation(saveWacMutation(wac)).then(result => result.data),
        patentLostPerOrg: drugId => graphQLWebservice.query(patentLostPerOrg(drugId)).then(result => result.data),
        savePatent: patent => graphQLWebservice.mutation(savePatentMutation(patent)).then(result => result.data),
        drugApprovalPerOrg: orgId => graphQLWebservice.query(drugApprovalPerOrg(orgId)).then(result => result.data),
        saveApproval: approval => graphQLWebservice.mutation(saveApprovalMutation(approval)).then(result => result.data),
    },
    company: {
        get: id => graphQLWebservice.query(companySingleQuery(id)).then(result => result.data.org),
        all: () => graphQLWebservice.query(companiesQuery()).then(result => result.data),
        save: company => graphQLWebservice.mutation(saveCompanyMutation(company)).then(result => result.data),
        delete: company => graphQLWebservice.mutation(deleteCompanyMutation(company)).then(result => result.data),
        inactivate: id => graphQLWebservice.mutation(inactivateCompanyMutation(id)),
        approve: ordId => graphQLWebservice.mutation(approveCompany(ordId)).then(result => result.data),
        submit: company => graphQLWebservice.mutation(submitCompanyMutation(company)).then(result => result.data),
    }
};
