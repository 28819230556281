import React, {useEffect, useState} from 'react';
import {Switch} from 'react-router-dom';
import {AjaxSpinner, OIDCCore} from "@utahdts/dts-react-common";
import Pages, {renderPageRoutes} from "../Pages";
import {useHistory} from "react-router";
import reduxStore from "../../redux/ReduxStore";
import reducers from "../../redux/Reducers";
import {observer} from "mobx-react";
import appStore from "../../mobx/appStore";
import AjaxSpinnerBackdrop from "@utahdts/dts-react-common/components/ajax-spinner/AjaxSpinnerBackdrop";
import {usePrevious} from "../../../misc/hooks/usePrevious";

const ProtectedRoutes = () => {
    const history = useHistory();
    const [renderRoutes, setRenderRoutes] = useState(false);
    const prevUser = usePrevious(appStore.loggedInUser);

    useEffect(() => {
        setRenderRoutes(!!appStore.loggedInUser);
    }, [appStore.loggedInUser]);

    return (
        <OIDCCore
            contentIfLoggingIn={() => <AjaxSpinner backdropType={AjaxSpinnerBackdrop.COVER_PAGE} size={100}/>}
            contentIfLoggedIn={() => renderRoutes ?
                <Switch>{renderPageRoutes(Pages)}</Switch>
                : <AjaxSpinner backdropType={AjaxSpinnerBackdrop.COVER_PAGE} size={100}/>}
            history={history}
            location={history.location}
            logoutDestinationUrl={window.location.origin}
            onLoginError={console.error}
            reduxReducers={reducers}
            reduxStore={reduxStore}
        />
    );
};

export default observer(ProtectedRoutes);