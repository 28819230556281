import React from 'react';
import {
    ColumnInformation,
    joinClassNames,
    Button,
    TableSorterField,
    TableSorterInformation, PopOver,
} from "@utahdts/dts-react-common";
import Icon from "../../misc/Icon";
import TableSorterSortType from "@utahdts/dts-react-common/components/table-sorter/TableSorterSortType";
import TableSorterDirection from "@utahdts/dts-react-common/components/table-sorter/TableSorterDirection";
import appStore from "../../app/mobx/appStore";
import Pages from "../../app/pages/Pages";
import FilterType from "@utahdts/dts-react-common/components/table/FilterType";
import {statuses} from "../../misc/Shared";

const customTd = (columnInfo, data, editCompany, inactivateCompany, approveCompany) => {
    return (
        <td key={joinClassNames(columnInfo.field, data.name)}>
            <PopOver
                anchor={(
                    <Button
                        label=""
                        children={Icon.iconEllipsis()}
                        className="button--naked ellipsis"
                        onClick={() => false}
                    />
                )}
                dismissible={true}
                mode={PopOver.MODE.CLICK}
                position={PopOver.POSITION.BOTTOM}
            >
                <ul className="actions">
                    <li className="actions-item" onClick={() => editCompany(false, data, true)}>{Icon.iconEye()} See Company</li>
                    {data.status !== statuses.APPROVED && <li className="actions-item" onClick={() => approveCompany(data)}>{Icon.iconCheckbox()} Approve Company</li>}
                    <li className="actions-item" onClick={() => {
                        data.label = data.name || data.fein;
                        data.value = data.id;
                        appStore.currentOrg = data;
                        Pages.users.forward();
                    }}>{Icon.iconUsers()} See Users</li>
                    {data.status !== 'Inactive' && <li className="actions-item" onClick={() => inactivateCompany(data)}>{Icon.iconBan()} Inactivate Company</li>}
                </ul>
            </PopOver>
        </td>
    )
};

const sorters = {
    name: new TableSorterField("name", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    fein: new TableSorterField("fein", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    city: new TableSorterField("city", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    zip: new TableSorterField("zip", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    status: new TableSorterField("status", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
};

const defaultSorters = firstSorter => new TableSorterInformation([
    firstSorter,
    ...(Object.values(sorters).filter(sorter => sorter !== firstSorter)),
]);

export default (editCompany, inactivateCompany, approveCompany) => [
    new ColumnInformation({title: 'Name', field: 'name', tableSorterInformation: defaultSorters(sorters.name)}),
    new ColumnInformation({title: 'FEIN', field: 'fein', tableSorterInformation: defaultSorters(sorters.fein)}),
    new ColumnInformation({title: 'City', field: 'city', tableSorterInformation: defaultSorters(sorters.city)}),
    new ColumnInformation({title: 'Zip', field: 'zip', tableSorterInformation: defaultSorters(sorters.zip)}),
    new ColumnInformation({title: 'Status', field: 'status', tableSorterInformation: defaultSorters(sorters.status), filterType: FilterType.SELECT}),
    new ColumnInformation({title: 'Actions', field: 'actions', tdCallback: (columnInfo, data) => customTd(columnInfo, data, editCompany, inactivateCompany, approveCompany), filter: false}),
];