import quoteGQLString from "../../util/quoteGQLString";

export default company => `
    id: ${quoteGQLString(company?.id)}
    name: ${quoteGQLString(company?.name)}
    fein: ${quoteGQLString(company?.fein)}
    address: ${quoteGQLString(company?.address)}
    address2: ${quoteGQLString(company?.address2)}
    city: ${quoteGQLString(company?.city)}
    state: ${quoteGQLString(company?.state)}
    zip: ${quoteGQLString(company?.zip)}
`