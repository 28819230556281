import utahHeaderLoaded from "./utahHeaderLoaded";
import {useEffect, useState} from "react";

// only the first call should inject the header, the rest should
// use the already created one
let isUtahHeaderLoaded = false;

/**
 * call in render of a functional component to get state change when
 * utah header is loaded
 *
 * @param utahHeaderVersion undefined for latest
 * @param utahHeaderUrl undefined for default
 * @return window.utahHeader or null until it is loaded
 */
export default ({utahHeaderVersion, utahHeaderUrl}) => {
    const [utahHeader, setUtahHeader] = useState(null);

    // load utah header - put in useEffect so it's not in the render
    // seems like it was getting a warning about updating DOM during a render?
    useEffect(() => {
        if (!isUtahHeaderLoaded) {
            isUtahHeaderLoaded = true;
            // add header bundle with current version (not necessarily latest)
            const script = document.createElement('script');
            const scriptUrl = utahHeaderUrl ?
                utahHeaderUrl :
                `https://cdn.utah.gov/utah-header/${utahHeaderVersion ? utahHeaderVersion.replace(/\./ig, '_') : 'latest'}/utahHeader.bundle.js`;
            script.setAttribute('src', scriptUrl);
            document.head.appendChild(script);
        }
    }, []);


    utahHeaderLoaded.then(() => setUtahHeader(window.utahHeader));

    return utahHeader;
};
