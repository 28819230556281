import React, {useEffect} from "react";
import Pages from "./Pages";
import isFunction from "lodash/isFunction";
import security from "../roles/security";
import appStore from "../mobx/appStore";
import historyStore from "../mobx/historyStore";
import Analytics from "../analytics/Analytics";

export default class Page {
    /**
     *
     * @param component React component for rendering content at this path
     * @param forward call this function to go to this page (if not provided, goes to path); useful for when there are parameters as part of the url
     * @param path the path to get to this url in Router lingo
     * @param render (optional) this is called to render this page (pass in the router for parameter matching)
     * @param title the title of this page to show in the tab
     * @param allRoles must have all these roles to render this page
     * @param anyRoles must have at least one of these roles to render this page
     */
    constructor({component, forward, path, render, title, allRoles, anyRoles}) {
        this.data = {
            component,
            allRoles,
            anyRoles,
        };

        forward = forward || (() => historyStore.history.push(path));
        Object.assign(this, {
            path,
            forward: forward && ((e, ...rest) => {
                if (e?.preventDefault) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                forward(e, ...rest);
            })
        });
        this.render = router => {
            useEffect(() => {
                appStore.pageTitle = title;
                Analytics.onPageChange();
            }, []);
            let result;
            if (this.checkRoles(router)) {
                result = render ? render(component, router) : <this.data.component {...(router?.match?.params || {})}/>;
            } else {
                result = Pages.home.render(router);
                historyStore.history.push('/');
            }
            return result;
        };
    }

    checkRoles = router =>
        (!this.data.allRoles && !this.data.anyRoles) ||
        (this.data.allRoles && (isFunction(this.data.allRoles) ? this.data.allRoles(router) : security.hasAllRoles(this.data.allRoles))) ||
        (this.data.anyRoles && (isFunction(this.data.anyRoles) ? this.data.anyRoles(router) : security.hasAnyRole(this.data.anyRoles)));
}
