import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import {
    Button,
    InputList,
    SelectInput, Size,
    validationEngineFromInputInformationList,
    ValidationStyle
} from "@utahdts/dts-react-common";
import useRefLazy from "../misc/hooks/useRefLazy";
import companyInputs from "./inputs/companyInputs";
import webservice from "../app/webservice/webservice";
import appStore from "../app/mobx/appStore";
import {usePrevious} from "../misc/hooks/usePrevious";
import roles from "../app/roles/roles";
import security from "../app/roles/security";
import Pages from "../app/pages/Pages";

const SingleCompany = () => {
    const [isBusy, setBusy] = useState(true);
    const [error, setError] = useState(undefined);
    const [users, setUsers] = useState([]);
    const prevOrg = usePrevious(appStore.currentOrg);
    const [primaryContact, setPrimary] = useState({
        old: null,
        new: null,
        value: null,
    });
    const [alternativeContact, setAlternative] = useState({
        old: null,
        new: null,
        value: null,
    });
    const [inputValues, setInputValues] = useState(() => ({
        id: null,
        name: null,
        fein: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
    }));
    const inputValuesRef = useRef();
    inputValuesRef.current = inputValues;

    const [validationEngineStyle, setValidationEngineStyle] = useState(ValidationStyle.BLURRED);
    const validationEngineRef = useRef(undefined);

    const inputs = useRefLazy(() => companyInputs({
        inputsOnChange: (field, value) => setInputValues(oldValues => ({ ...oldValues, [field]: value }))
    }, true, users));

    const [validationEngine, setValidationEngine] = useState(undefined);
    validationEngineRef.current = validationEngine;
    if (validationEngine) {
        validationEngine.validationStyle = validationEngineStyle;
    }

    const submit = () => {
        setValidationEngineStyle(ValidationStyle.ALWAYS);
        if (validationEngineRef.current && validationEngineRef.current.isValid(inputValuesRef.current) && primaryContact.new) {
            setBusy(true);
            setError(undefined);
            onSave({
                ...inputValues,
                primaryContact: primaryContact.new,
                alternativeContact: alternativeContact.new
            }, primaryContact.old, alternativeContact.old).catch(() => setBusy(false));
        } else if (!primaryContact.new) {
            setError('A primary contact is required.');
        }
    };

    const onSave = (company, oldPrimary, oldAlt) => {
        let queries = [];
        company.primaryContact?.id && queries.push(checkContact(company, oldPrimary, "primaryContact"));
        company.alternativeContact?.id && queries.push(checkContact(company, oldAlt, "alternativeContact"));
        queries.push(webservice.company.save(company));
        return Promise.all(queries).then(() => {
            setBusy(false);
        });
    };

    const checkContact = (company, oldContact, field) => {
        if(company[field]?.id !== oldContact?.id) {
            let fieldRole = oldContact?.roles.findIndex(role => role.org === company.id && role[field]);
            if(fieldRole >= 0){
                let userRole = oldContact.roles[fieldRole];
                userRole[field] = false;
                saveContact(oldContact);
            }
            return setContact(company[field], field);
        } else {
            return setContact(oldContact, field);
        }
    };

    const setContact = (user, field) => {
        let isOrgAdmin = user.roles.findIndex(role => role.org === inputValues.id && role.role === roles.orgAdmin);
        let isField = user.roles.findIndex(role => role.org === inputValues.id && role[field]);
        if(isOrgAdmin >= 0) {
            let userRoles = user.roles[isOrgAdmin];
            userRoles[field] = true;
            isField >= 0 && isOrgAdmin !== isField && user.roles.splice(isField, 1);
        } else if(isField >= 0) {
            user.roles[isField].role = roles.orgAdmin;
        } else {
            user.roles.push({
                org: inputValues.id,
                role: roles.orgAdmin,
                [field]: true,
            })
        }
        return saveContact(user);
    };

    const saveContact = user => webservice.user.save(user);

    const formatUsers = data => {
        const options = data.usersByOrg.map(user =>  ({...user, value: user.id, label: user.name}));
        const primary = data.usersByOrg.find(user => user.roles.find(role => role.primaryContact));
        const alternative = data.usersByOrg.find(user => user.roles.find(role => role.alternativeContact));
        setPrimary({old: primary, value: primary?.id, new: primary});
        setAlternative({old: alternative, value: alternative?.id, new: alternative});
        setUsers(options);
        setBusy(false);
    };

    const getInfo = () => {
        appStore.currentOrg?.id && webservice.company.get(appStore.currentOrg?.id).then(org => setInputValues({...org}));
    };

    const getUsers = () => {
        appStore.currentOrg?.id && webservice.user.perOrg(appStore.currentOrg?.id).then(formatUsers);
    };

    useEffect(() => {
        setValidationEngine(validationEngineFromInputInformationList(inputs.current));
    }, [inputs.current]);

    useEffect(() => {
        if(prevOrg !== appStore.currentOrg) {
            setBusy(true);
            if(security.hasAnyRole([roles.orgAdmin])) {
                getInfo();
                getUsers();
            } else {
                Pages.home.forward();
            }
        }
    }, [appStore.currentOrg]);

    useEffect(() => {
        getUsers();
        getInfo();
    }, []);

    return (
        <div key="listings" className="home-wrapper">
            <div className="home-content">
                <h1 className="mb-zero">Company Info</h1>
            </div>
            <div>
                <InputList inputs={Object.values(inputs.current)} data={inputValues} validationEngine={validationEngine} className="stack-form flex flex-wrap half-form" />
                <section className="stack-form">
                    <SelectInput
                        field='contactPrimary'
                        label='Primary Contact:'
                        multiSelect={false}
                        value={primaryContact.value}
                        onChange={(field, value) => setPrimary(oldValues => ({ ...oldValues, value: value, new: users.find(user => user.id === value)}))}
                        options={users}
                        allowClear={false}
                        size={Size.FULL_WIDTH}
                        className="required"/>
                    <SelectInput
                        field='alternativeContact'
                        label='Alternative Contact:'
                        multiSelect={false}
                        value={alternativeContact.value}
                        onChange={(field, value) => setAlternative(oldValues => ({ ...oldValues, value: value, new: users.find(user => user.id === value)}))}
                        options={users}
                        allowClear={false}
                        size={Size.FULL_WIDTH}/>
                </section>
                {error && <div className="form-error">{error}</div>}
                <Button label="Save" busy={isBusy} onClick={submit} key="save-button"/>
            </div>
        </div>
    )
};

export default observer(SingleCompany);