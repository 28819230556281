import React from 'react';
import {
    ColumnInformation,
    joinClassNames,
    Button,
    TableSorterField,
    TableSorterInformation, PopOver,
} from "@utahdts/dts-react-common";
import Icon from "../../misc/Icon";
import TableSorterSortType from "@utahdts/dts-react-common/components/table-sorter/TableSorterSortType";
import TableSorterDirection from "@utahdts/dts-react-common/components/table-sorter/TableSorterDirection";
import appStore from "../../app/mobx/appStore";
import Pages from "../../app/pages/Pages";
import {textTd, truncate} from "../../misc/Shared";

const sorters = {
    description: new TableSorterField("description", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    ndc: new TableSorterField("ndc", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
};

const defaultSorters = firstSorter => new TableSorterInformation([
    firstSorter,
    ...(Object.values(sorters).filter(sorter => sorter !== firstSorter)),
]);

export default (editDrug) => [
    new ColumnInformation({title: 'Description', field: 'description', tableSorterInformation: defaultSorters(sorters.description), tdCallback: textTd}),
];