export default () => `
    id
    org
    ndc
    drugType
    drugDescription
    effectiveDate
    increaseAmount
    wacAfterIncrease
    historyOneYear
    historyTwoYear
    increaseFactors
    rdCosts
    comments
    createdDate
    createdBy
`