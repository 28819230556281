import React from "react";
import Inputmask from 'inputmask';
import {joinClassNames} from "@utahdts/dts-react-common";
import isoDate from "./isoDate";

export const formatDate = timestamp => {
    let date = new Date(timestamp);
    return `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
};

export const createArray = (start, stop, step) => Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step));

export const COLOR_PALETTE = ["#44AA99", "#117733", "#999933", "#DDCC77", "#88CCEE", "#AA4499", "#CC6677", "#882255", "#332288"];

export const checkURL = url => {
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);
    if (!url.match(regex)) {
        url = 'https://' + url;
    }
    return url;
};

export const statuses = {
    PENDING: 'Pending',
    APPROVED: 'Approved'
};

export const truncate = (str, n) => {
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

export const textTd = (columnInfo, data) => {
    return (
        <td key={joinClassNames(columnInfo.field, data.name)} className="word-break">
            {data[columnInfo.field] && truncate(data[columnInfo.field], 250)}
        </td>
    )
};

export const dateTd = (columnInfo, data, full) => {
    const regexA = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    const regexB = /(\d{2})\/(\d{2})\/(\d{4})T(\d{2}):(\d{2}):(\d{2})/;
    const matchesA = data[columnInfo.field] && data[columnInfo.field].match(regexA);
    const matchesB = data[columnInfo.field] && data[columnInfo.field].match(regexB);
    const date = matchesA ?
        `${matchesA[2]}/${matchesA[3]}/${matchesA[1]}${full ? ` ${matchesA[4]}:${matchesA[5]}:${matchesA[6]}` : ''}` :
        matchesB ? `${matchesB[1]}/${matchesB[2]}/${matchesB[3]}${full ? `${matchesB[4]}:${matchesB[5]}:${matchesB[6]}` : ''}` : data[columnInfo.field];
    return (
        <td key={joinClassNames(columnInfo.field, data.name)}>
            {date}
        </td>
    )
};

export const CONTEXT_URL_REPORT = {
    LOCAL:  'https://us-central1-ut-uid-pharma-dev.cloudfunctions.net/gen-report-ws',
    DEV:    'https://us-central1-ut-uid-pharma-dev.cloudfunctions.net/gen-report-ws',
    AT:     'https://us-central1-ut-uid-pharma-at.cloudfunctions.net/gen-report-ws',
    PROD:   'https://us-central1-ut-uid-pharma-prod.cloudfunctions.net/gen-report-ws'
};