import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Button, Table, MessagePopupCore} from "@utahdts/dts-react-common";
import Icon from "../misc/Icon";
import appStore from "../app/mobx/appStore";
import columns from "./columns/WacColumns"
import webservice from "../app/webservice/webservice";
import Wac from "./modals/Wac";
import * as Excel from "exceljs";
import dates from "../misc/isoDate";
import security from "../app/roles/security";
import roles from "../app/roles/roles";
import {usePrevious} from "../misc/hooks/usePrevious";

const Wacs = () => {
    const [isBusy, setBusy] = useState(true);
    const [wacs, setWacs] = useState([]);
    const [showWac, displayWac] = useState(false);
    const [wac, setWac] = useState({});
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState(undefined);
    const prevOrg = usePrevious(appStore.currentOrg);
    const fileInput = React.createRef();
    let files = [];

    const checkHeader = array => {
        for(const v of Object.keys(templateHeader)) {
            if(!array.includes(templateHeader[v].header)) return false;
        }
        return true;
    };

    const checkNumber = value => {
        let test = Number(value);
        test = typeof test === "number" ? test : false;
        return test;
    };

    const checkDate = date => {
        let test = date ? new Date(date) : false;
        return test === "Invalid date" ? false : test && dates.convert(dates.formatDateFromISO(date));
    };

    const checkString = value => {
        return value ? String(value) : '';
    };


    const templateHeader = {
        ndc: {
            header: "NDC",
            checker: checkString,
            type: 'string',
            isRequired: true
        },
        drugDescription: {
            header: "DRUG_PROD_DESC",
            checker: checkString,
            type: 'string',
            isRequired: true
        },
        drugType: {
            header: "DRUG_TYPE",
            checker: checkString,
            type: 'string',
            isRequired: true
        },
        effectiveDate: {
            header: "WAC_EFFECTIVE_DATE",
            checker: checkDate,
            type: 'date',
            isRequired: true
        },
        increaseAmount: {
            header: "WAC_INCREASE_AMOUNT",
            checker: checkString,
            type: 'string',
            isRequired: false
        },
        wacAfterIncrease: {
            header: "WAC_AFTER_INCREASE",
            checker: checkNumber,
            type: 'number',
            isRequired: false
        },
        historyOneYear: {
            header: "WAC_HISTORY_1YR",
            checker: checkNumber,
            type: 'number',
            isRequired: false
        },
        historyTwoYear: {
            header: "WAC_HISTORY_2YRS",
            checker: checkNumber,
            type: 'number',
            isRequired: false
        },
        increaseFactors: {
            header: "COST_INCREASE_FACTORS",
            checker: checkString,
            type: 'string',
            isRequired: true
        },
        rdCosts: {
            header: "RD_COSTS",
            checker: checkNumber,
            type: 'string',
            isRequired: false
        },
        comments: {
            header: "GENERAL_COMMENTS",
            checker: checkString,
            type: 'string',
            isRequired: false
        },
    };

    const fileSelected = () => {
        setBusy(true);
        let orgID = security.hasAnyRole([roles.dev, roles.admin]) ? "" : appStore.currentOrg ? appStore.currentOrg.id : undefined;
        const uploadedFiles = fileInput.current.files;
        if (uploadedFiles.length === 0) {
            files = [];
        } else {
            files = [].concat(uploadedFiles[0]);
            appStore.filesNames = [].concat(Array.from(fileInput.current.files).map(file => file.name));
        }

        const keys = Object.keys(templateHeader);
        const workbook = new Excel.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(files[0]);
        reader.onload = () => {
            const buffer = reader.result;
            workbook.xlsx.load(buffer).then(workbook => {
                let sheet = workbook.worksheets[0];
                let header = sheet.getRow(1).values;
                if(checkHeader(header)){
                    let wacs = [];
                    const indexes = {};
                    keys.forEach(k => indexes[k] = header.findIndex(e => e === templateHeader[k].header));
                    sheet.eachRow((row) => {
                        const ndcValue = row.getCell(indexes.ndc).value.toString();
                        let ndc = ndcValue && ndcValue.match(/\d+/g) ? ndcValue.match(/\d+/g).join('') : ndcValue;
                        if(Number(ndc)) {
                            while (ndc.length < 11) {
                                ndc = '0' + ndc.toString();
                            }
                            let wac = {};
                            let toPush = true;
                            wac.org = orgID;
                            keys.forEach(k => {
                                const value = templateHeader[k].checker(row.getCell(indexes[k]).value);
                                wac[k] = k === 'ndc' ? ndc : templateHeader[k].type === 'string' ? value : value;
                                //if those values are empty, we set them to 0
                                if(!value && ['increaseAmount', 'wacAfterIncrease', 'historyOneYear', 'historyTwoYear', 'rdCosts'].includes(k)) {
                                    wac[k] = k === 'rdCosts' ? checkString("0") : templateHeader[k].checker(0);
                                }
                                if(!value && templateHeader[k].isRequired) { toPush = false }
                            });
                            toPush && wacs.push(wac);
                        }
                    });
                    if(wacs.length > 0){
                        let promises = [];
                        wacs.forEach(w => promises.push(webservice.wac.save(w)));
                        Promise.all(promises).then(() => {
                            getWacs();
                            MessagePopupCore.addMessage({
                                title: 'Data uploaded',
                                message: <p>The current drug listing has been updated successfully.<br/>
                                    {wacs.length} records added.</p>
                            })
                        }).catch(displayError)
                    } else {
                        setBusy(false);
                        MessagePopupCore.addMessage({
                            title: 'Invalid data',
                            message: <p>Some data might be missing or formatted incorrectly. <br/>
                                Please make sure the file and its content are correct.</p>
                        })
                    }
                } else {
                    setBusy(false);
                    MessagePopupCore.addMessage({
                        title: 'Invalid data',
                        message: <ul>Please check the header row and make sure those fields are present:
                            {keys.map(h => <li key={h}>{templateHeader[h].header}</li>)}
                        </ul>
                    })
                }
            })
        }
    };

    const displayError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred. Please try again later.'
        })
    };

    const editWac = (isNew, wac, showWac) => {
        setIsNew(isNew);
        let orgID = security.hasAnyRole([roles.dev, roles.admin]) ? "" : appStore.currentOrg ? appStore.currentOrg.id : undefined;
        wac.ndc = wac.ndc ? wac.ndc : undefined;
        wac.org = wac.org ? wac.org : orgID;
        setWac(wac);
        displayWac(showWac);
    };

    const resetWac = () => {
        editWac(true, {}, false);
        setError(undefined);
    };

    const saveWac = wac => webservice.wac.save(wac).then(() => {
        setBusy(true);
        resetWac();
        getWacs();
    }).catch(displayError);

    const getWacs = () => {
        let orgID = security.hasAnyRole([roles.dev, roles.admin]) ? "" : appStore.currentOrg ? appStore.currentOrg.id : "not found";
        webservice.wac.perOrg(orgID).then(data => {
            setWacs(data.wacsByOrg);
            setBusy(false);
        }).catch(displayError);
    };

    useEffect(() => {
        if(prevOrg !== appStore.currentOrg) {
            setBusy(true);
            getWacs();
        }
    }, [appStore.currentOrg]);

    useEffect(() => {
        getWacs();
    }, []);

    return (
        <div key="listings" className="home-wrapper">
            <div className="home-content">
                <h1 className="mb-zero">Drug Filings (WAC)</h1>
            </div>
            <div className="buttons flex">
                <Button
                    label="Download Template"
                    className="mr-spacing flex align-items-center"
                    onClick={() => window.location.href = './drug-wac-increase.xlsx'}
                    children={<span className="icon--round ml-spacing">{Icon.iconDownload()}</span>}
                    childrenPostion={Button.CHILDREN_POSITION.AFTER}
                />
                <input type="file" style={{display: 'none'}} ref={fileInput} onChange={fileSelected}
                       id="filePicker" name="filePicker" accept=".xlsx" onClick={e => e.target.value = null}/>
                <Button
                    label="Upload WAC"
                    className="mr-spacing flex align-items-center"
                    onClick={() => fileInput.current.click()}
                    children={<span className="icon--round ml-spacing">{Icon.iconUpload()}</span>}
                    childrenPostion={Button.CHILDREN_POSITION.AFTER}
                    busy={isBusy}
                />
                <Button
                    label="Manually Enter WAC"
                    className="mr-spacing secondary"
                    onClick={() => editWac(true, {}, true)}
                />
            </div>
            <div>
                <Table
                    list={wacs}
                    columns={columns(editWac)}
                    filters={true}
                    showLimit={50}
                    ajaxSpinner={isBusy}
                />
            </div>
            {showWac && <Wac
                onClose={resetWac}
                onSave={saveWac}
                isNew={isNew}
                wac={wac}
            />}
        </div>
    )
};

export default observer(Wacs);
