import Pages from "../pages/Pages";
import {OIDCOptions} from "@utahdts/dts-react-common";
import appStore from "../mobx/appStore";

export default utahHeader => ({
    headerStyle: utahHeader?.HEADER_STYLE.RED_DARK,
    headerLogo: '',
    headerLogoType: 'string',
    headerTitle: 'UID Pharmacy',
    headerTitleOnClick: Pages.home.forward,
    utahIdCustom: {
        onUserClickedSignIn: Pages.home.forward,
        onUserClickedSignOut: () => {
            appStore.signOut();
            window.location = OIDCOptions.paths.logout();
        },
        profileURL: 'https://id.utah.gov',
    },

});
