import React, {useEffect, useState, useRef} from 'react';
import {
    PopupWindow,
    Button,
    ValidationStyle,
    InputList,
    validationEngineFromInputInformationList, SelectInput, Size, MessagePopupCore
} from '@utahdts/dts-react-common';
import PropTypes from "prop-types";
import useRefLazy from "../../misc/hooks/useRefLazy";
import companyInputs from "../inputs/companyInputs";
import webservice from "../../app/webservice/webservice";
import userInputs from "../inputs/userInputs";
import roles from "../../app/roles/roles";

const propTypes = {
    company: PropTypes.object.isRequired,

    isNew: PropTypes.bool.isRequired,

    onClose: PropTypes.func.isRequired,

    onSave: PropTypes.func.isRequired,
};
const defaultProps = {};

const Company = ({company, isNew, onClose, onSave}) => {
    const [isBusy, setBusy] = useState(true);
    const [error, setError] = useState(undefined);
    const [users, setUsers] = useState([]);
    const [primaryContact, setPrimary] = useState({
        old: null,
        new: null,
        value: null,
    });
    const [alternativeContact, setAlternative] = useState({
        old: null,
        new: null,
        value: null,
    });
    const [inputValues, setInputValues] = useState(() => (Object.assign({
        id: null,
        name: null,
        fein: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
    }, company)));
    const inputValuesRef = useRef();
    inputValuesRef.current = inputValues;

    const [validationEngineStyle, setValidationEngineStyle] = useState(ValidationStyle.BLURRED);
    const validationEngineRef = useRef(undefined);

    const inputs = useRefLazy(() => companyInputs({
        inputsOnChange: (field, value) => setInputValues(oldValues => ({ ...oldValues, [field]: value }))
    }, true, users));

    const [validationEngine, setValidationEngine] = useState(undefined);
    validationEngineRef.current = validationEngine;
    if (validationEngine) {
        validationEngine.validationStyle = validationEngineStyle;
    }


    const [contactValues, setContactValues] = useState({
        name: null,
        email: null,
        phone: null,
    });
    const contactValuesRef = useRef();
    contactValuesRef.current = contactValues;

    const [contactValidationStyle, setContactValidationStyle] = useState(ValidationStyle.BLURRED);
    const contactValidationRef = useRef(undefined);

    const contactInputs = useRefLazy(() => userInputs({
        inputsOnChange: (field, value) => setContactValues(oldValues => ({ ...oldValues, [field]: value }))
    }, true, true));

    const [contactValidation, setContactValidation] = useState(undefined);
    contactValidationRef.current = contactValidation;
    if (contactValidation) {
        contactValidation.validationStyle = contactValidationStyle;
    }

    const [alternateValues, setAlternateValues] = useState({
        name: null,
        email: null,
        phone: null,
    });
    const alternateValuesRef = useRef();
    alternateValuesRef.current = alternateValues;

    const [alternateValidationStyle, setAlternateValidationStyle] = useState(ValidationStyle.BLURRED);
    const alternateValidationRef = useRef(undefined);

    const alternateInputs = useRefLazy(() => userInputs({
        inputsOnChange: (field, value) => setAlternateValues(oldValues => ({ ...oldValues, [field]: value }))
    }, true, false));

    const [alternateValidation, setAlternateValidation] = useState(undefined);
    alternateValidationRef.current = alternateValidation;
    if (alternateValidation) {
        alternateValidation.validationStyle = alternateValidationStyle;
    }

    const submit = () => {
        setValidationEngineStyle(ValidationStyle.ALWAYS);
        if(isNew) {
            setContactValidationStyle(ValidationStyle.ALWAYS);
            setAlternateValidationStyle(ValidationStyle.ALWAYS);
            setContacts();
        } else {
            setCompany();
        }
    };

    const setCompany = () => {
        if (validationEngineRef.current && validationEngineRef.current.isValid(inputValuesRef.current) && primaryContact.new) {
            setBusy(true);
            setError(undefined);
            onSave({
                ...inputValues,
                primaryContact: primaryContact.new,
                alternativeContact: alternativeContact.new
            }, primaryContact.old, alternativeContact.old).catch(() => setBusy(false));
        } else if (!primaryContact.new) {
            setError('A primary contact is required.');
        }
    };

    const setContacts = () => {
        if(contactValidationRef.current && contactValidationRef.current.isValid(contactValuesRef.current)
            && alternateValidationRef.current && alternateValidationRef.current.isValid(alternateValuesRef.current)) {
            setBusy(true);
            setError(undefined);
            webservice.company.submit(inputValues).then(data => {
                let queries = [];
                const org = data.submitOrg;
                let primary = setUser(contactValues, org, true);
                let alternative = alternateValues.email && setUser(alternateValues, org, false);
                queries.push(webservice.user.submit(primary));
                alternative && queries.push(webservice.user.submit(alternative));
                Promise.all(queries).then(() => onClose(true)).catch(displayError);
            }).catch(displayError);
        }
    };

    const setUser = (inputs, org, isPrimary) => {
        return {
            name: inputs.name,
            email: inputs.email,
            phone: inputs.phone,
            orgs: [org.id],
            submitOrg: org.id,
            roles: [{
                role: roles.orgAdmin,
                org: org.id,
                primaryContact: isPrimary,
                alternativeContact: !isPrimary
            }]
        };
    };

    const formatUsers = data => {
        const options = data.usersByOrg.map(user =>  ({...user, value: user.id, label: user.name}));
        const primary = isNew ? undefined : data.usersByOrg.find(user => user.roles.find(role => role.primaryContact));
        const alternative = isNew ? undefined : data.usersByOrg.find(user => user.roles.find(role => role.alternativeContact));
        setPrimary({old: primary, value: primary?.id, new: primary});
        setAlternative({old: alternative, value: alternative?.id, new: alternative});
        setUsers(options);
        setBusy(false);
    };

    const displayError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred. Please try again later.'
        })
    };

    useEffect(() => {
        setValidationEngine(validationEngineFromInputInformationList(inputs.current));
        if(isNew) {
            setContactValidation(validationEngineFromInputInformationList(contactInputs.current));
            setAlternateValidation(validationEngineFromInputInformationList(alternateInputs.current));
        }
    }, [inputs.current]);

    useEffect(() => {
        if(isNew) {
            setBusy(false);
        } else {
            webservice.user.perOrg(company.id).then(formatUsers);
        }
    }, []);

    return (
        <PopupWindow
            className="popup-window--large"
            onCloseButtonCallback={() => onClose(false)}
            footerChildren={[
                <Button label="Close" onClick={() => onClose(false)} key="close-button" className="button--black"/>,
                <Button label="Save" busy={isBusy} onClick={submit} key="save-button"/>
            ]}>
            <h3>{inputValues.id ? 'Edit company' : 'Add new company'}</h3>
            <h4 className="mb-spacing--small mt-zero">Company info</h4>
            <InputList inputs={Object.values(inputs.current)} data={inputValues} validationEngine={validationEngine}
                       className="stack-form flex flex-wrap half-form"/>
            {isNew ? <section>
                    <h4 className="mb-spacing--small mt-zero">Primary contact</h4>
                    <InputList inputs={Object.values(contactInputs.current)} data={contactValues}
                               validationEngine={contactValidation} className="stack-form flex flex-wrap half-form"/>
                    <h4 className="mb-spacing--small mt-zero">Alternative contact</h4>
                    <InputList inputs={Object.values(alternateInputs.current)} data={alternateValues}
                               validationEngine={alternateValidation} className="stack-form flex flex-wrap half-form" />
                </section>
                : <section className="stack-form">
                    <SelectInput
                        field='contactPrimary'
                        label='Primary Contact:'
                        multiSelect={false}
                        value={primaryContact.value}
                        onChange={(field, value) => setPrimary(oldValues => ({
                            ...oldValues,
                            value: value,
                            new: users.find(user => user.id === value)
                        }))}
                        options={users}
                        allowClear={false}
                        size={Size.FULL_WIDTH}
                        className="required"/>
                    <SelectInput
                        field='alternativeContact'
                        label='Alternative Contact:'
                        multiSelect={false}
                        value={alternativeContact.value}
                        onChange={(field, value) => setAlternative(oldValues => ({
                            ...oldValues,
                            value: value,
                            new: users.find(user => user.id === value)
                        }))}
                        options={users}
                        allowClear={false}
                        size={Size.FULL_WIDTH}/>
                </section>}
            {error && <div className="form-error">{error}</div>}
        </PopupWindow>
    )
};

Company.propTypes = propTypes;
Company.defaultProps = defaultProps;

export default Company;