import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import appStore from "../app/mobx/appStore";
import {
    Button,
    InputList, MessagePopupCore,
    validationEngineFromInputInformationList,
    ValidationStyle
} from "@utahdts/dts-react-common";
import useRefLazy from "../misc/hooks/useRefLazy";
import reportInputs from "./inputs/reportInputs";
import {CONTEXT_URL_REPORT} from "../misc/Shared";

const Report = () => {
    const [isBusy, setBusy] = useState(false);
    const [inputValues, setInputValues] = useState(() => ({
        startdate: null,
        enddate: null,
    }));
    const [sheet, setSheet] = useState(undefined);

    const inputValuesRef = useRef();
    inputValuesRef.current = inputValues;

    const [validationEngineStyle, setValidationEngineStyle] = useState(ValidationStyle.BLURRED);
    const validationEngineRef = useRef(undefined);

    const inputs = useRefLazy(() => reportInputs({
        inputsOnChange: (field, value) => setInputValues(oldValues => ({ ...oldValues, [field]: value }))
    }));

    const [validationEngine, setValidationEngine] = useState(undefined);
    validationEngineRef.current = validationEngine;
    if (validationEngine) {
        validationEngine.validationStyle = validationEngineStyle;
    }

    const CONTEXT_SHEET = {
        LOCAL:  'https://docs.google.com/spreadsheets/d/1ytqBYnSow-Z8rkZpi5lFKCr0r0wGL3qQxgNvCIgrWVU/edit?pli=1#gid=0',
        DEV:    'https://docs.google.com/spreadsheets/d/1ytqBYnSow-Z8rkZpi5lFKCr0r0wGL3qQxgNvCIgrWVU/edit?pli=1#gid=0',
        AT:     'https://docs.google.com/spreadsheets/d/1NEv_zzbPUyUI9o5_niqLlV1anu2CKwXkAf90UjqrAgU/edit#gid=0',
        PROD:   'https://docs.google.com/spreadsheets/d/1Xxy2L5EEUV1dF8Xj39Ii8oaUXWhb1uHT1nbP2qMlZbo/edit#gid=0'
    };

    const formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    const submit = () => {
        setValidationEngineStyle(ValidationStyle.ALWAYS);
        if (validationEngineRef.current && validationEngineRef.current.isValid(inputValuesRef.current)) {
            const currentContext = (document.location.hostname.includes('localhost')) ? CONTEXT_URL_REPORT.LOCAL
                : (document.location.hostname.includes('.dev.')) ? CONTEXT_URL_REPORT.DEV
                    : (document.location.hostname.includes('.at.')) ? CONTEXT_URL_REPORT.AT
                        : CONTEXT_URL_REPORT.PROD;
            setBusy(true);
            setSheet(undefined);
            const url = currentContext;
            const data = {
                startdate: formatDate(inputValues.startdate),
                enddate: formatDate(inputValues.enddate),
            };
            const otherParams = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appStore.oidcUser ? `Bearer ${appStore.oidcUser.access_token}` : false
                },
                body: JSON.stringify(data),
                method: 'POST',
            };
            fetch(url, otherParams)
                .then(response => {
                    const currentSheet = (document.location.hostname.includes('localhost')) ? CONTEXT_SHEET.LOCAL
                        : (document.location.hostname.includes('.dev.')) ? CONTEXT_SHEET.DEV
                            : (document.location.hostname.includes('.at.')) ? CONTEXT_SHEET.AT
                                : CONTEXT_SHEET.PROD;
                    setSheet(currentSheet);
                    setBusy(false);
                })
                .catch(error => {
                    displayError(error)
                })
        }
    };

    const displayError = e => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: <p>An error occurred. Please try again later. <br/>{e.toString()}</p>
        })
    };

    useEffect(() => {
        setValidationEngine(validationEngineFromInputInformationList(inputs.current));
    }, [inputs.current]);

    return (
        <div key="home" className="home-wrapper">
            <InputList inputs={Object.values(inputs.current)} data={inputValues} validationEngine={validationEngine} className="stack-form flex flex-wrap half-form" />
            <Button label="Submit" busy={isBusy} onClick={submit} key="save-button"/>
            {sheet && <Button label="Access Report" busy={isBusy} onClick={() => window.open(sheet, '_blank')} key="report-button" className="ml-spacing button--black"/>}
        </div>
    );
};

export default observer(Report);
