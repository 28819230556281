import {action, makeAutoObservable} from "mobx";

export default new class {
    history = null;

    constructor() {
        makeAutoObservable(this);
    }

    forward = path => this.history.push(path);

    back = () => this.history.goBack();
}();
