import ReactGA from 'react-ga';
import urlContext, {Contexts} from "../urlContext/urlContext";


const isLocalHost = urlContext() === Contexts.LOCAL;
let isLocalHostEnabled = false;

/**
 * functions for dealing with google analytics in a Single Page App paradigm
 */
export default {
    /**
     * call this once at app launch to setup google analytics
     *
     * @param trackingCode the google provided user code usually in the format of `UA-xxxx-#`
     * @param enableLocalHost bool true to have analytics sent even if running on localhost url
     */
    initialize: (trackingCode, enableLocalHost = false) => {
        isLocalHostEnabled = enableLocalHost;
        if (!isLocalHost || enableLocalHost) {
            ReactGA.initialize(trackingCode);
        }
    },

    /**
     * call this when the page path changes to have notification
     * sent to google analytics
     *
     * note: does not use `Page.path` here as path may have dynamic routing
     * pieces (ie my/path/:id/:childId) which would not be a valid path without
     * having those variable pieces filled in
     */
    onPageChange: () => (isLocalHostEnabled || !isLocalHost) && ReactGA.pageview(window.location.pathname),
};
