import React, {useEffect, useState, useRef} from 'react';
import {
    PopupWindow,
    Button,
    ValidationStyle,
    InputList,
    validationEngineFromInputInformationList
} from '@utahdts/dts-react-common';
import PropTypes from "prop-types";
import useRefLazy from "../../misc/hooks/useRefLazy";
import approvalInputs from "../inputs/approvalInputs";

const propTypes = {
    approval: PropTypes.object.isRequired,

    isNew: PropTypes.bool.isRequired,

    onClose: PropTypes.func.isRequired,

    onSave: PropTypes.func.isRequired,

    error: PropTypes.string
};
const defaultProps = {};

const Approval = ({approval, isNew, onClose, onSave, error}) => {
    const [isBusy, setBusy] = useState(false);

    const [inputValues, setInputValues] = useState(() => (Object.assign({
        id: null,
        org: null,
        fdaApprovalDate: null,
        drugDescription: null,
    }, approval)));
    const inputValuesRef = useRef();
    inputValuesRef.current = inputValues;

    const [validationEngineStyle, setValidationEngineStyle] = useState(ValidationStyle.BLURRED);
    const validationEngineRef = useRef(undefined);

    const inputs = useRefLazy(() => approvalInputs({
        inputsOnChange: (field, value) => setInputValues(oldValues => ({ ...oldValues, [field]: value }))
    }, isNew));

    const [validationEngine, setValidationEngine] = useState(undefined);
    validationEngineRef.current = validationEngine;
    if (validationEngine) {
        validationEngine.validationStyle = validationEngineStyle;
    }

    const submit = () => {
        setValidationEngineStyle(ValidationStyle.ALWAYS);
        if (validationEngineRef.current && validationEngineRef.current.isValid(inputValuesRef.current)) {
            setBusy(true);
            onSave(inputValues).catch(() => setBusy(false));
        }
    };

    useEffect(() => {
        setValidationEngine(validationEngineFromInputInformationList(inputs.current));
    }, [inputs.current]);

    return (
        <PopupWindow
            className="popup-window--large"
            onCloseButtonCallback={onClose}
            footerChildren={[
                <Button label="Close" onClick={onClose} key="close-button" className="button--black"/>,
                <Button label={isNew ? "Save" : "Okay"} busy={isBusy} onClick={isNew ? submit : onClose} key="save-button"/>
            ]}>
            <h3>{inputValues.id ? 'Edit approval' : 'Add new approval'}</h3>
            <InputList inputs={Object.values(inputs.current)} data={inputValues} validationEngine={validationEngine} className="stack-form flex flex-wrap half-form" />
            {error && <div className="form-error">{error}</div>}
        </PopupWindow>
    )
};

Approval.propTypes = propTypes;
Approval.defaultProps = defaultProps;

export default Approval;
