import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import security from "../app/roles/security";
import roles from "../app/roles/roles";
import appStore from "../app/mobx/appStore";
import {Button} from "@utahdts/dts-react-common";
import historyStore from "../app/mobx/historyStore";
import {CONTEXT_URL_REPORT} from "../misc/Shared";

const defaultMenu = {
    report: {
        text: 'Report',
        roles: [roles.admin, roles.dev],
        onClick: () => historyStore.forward('/report'),
    },
    listings: {
        text: 'Drug Listings',
        roles: [roles.admin, roles.dev, roles.orgAdmin, roles.user],
        onClick: () => historyStore.forward('/listings'),
    },
    wacs: {
        text: 'WAC History',
        roles: [roles.admin, roles.dev, roles.orgAdmin, roles.user],
        onClick: () => historyStore.forward('/wacs'),
    },
    approvals: {
        text: 'Approval History',
        roles: [roles.admin, roles.dev, roles.orgAdmin, roles.user],
        onClick: () => historyStore.forward('/approvals'),
    },
    patents: {
        text: 'Patent History',
        roles: [roles.admin, roles.dev, roles.orgAdmin, roles.user],
        onClick: () => historyStore.forward('/patents'),
    },
    users: {
        text: 'Users',
        roles: [roles.admin, roles.dev, roles.orgAdmin],
        onClick: () => historyStore.forward('/users'),
    },
    companies: {
        text: 'Companies',
        roles: [roles.admin, roles.dev],
        onClick: () => historyStore.forward('/companies'),
    },
    company: {
        text: 'Company',
        roles: [roles.orgAdmin],
        onClick: () => historyStore.forward('/company'),
    },
};

const Home = () => {
    const [menu, setMenu] = useState([]);

    const setButtons = () => {
        let buttons = Object.keys(defaultMenu)
            .filter(menuKey => {
                let allow = true;

                if(defaultMenu[menuKey].roles) {
                    allow = security.hasAnyRole(defaultMenu[menuKey].roles);
                }

                if(menuKey === 'switch') {
                    allow = !security.hasAnyRole([roles.dev, roles.admin]) &&
                        appStore.loggedInUser?.roles ? [...new Set(appStore.loggedInUser?.roles.map(role => role.org))].length > 1 : false;
                }

                return allow;
            })
            .map(menuKey => defaultMenu[menuKey]);
        setMenu(buttons);
    };

    const wakeReportUp = () => {
        const currentContext = (document.location.hostname.includes('localhost')) ? CONTEXT_URL_REPORT.LOCAL
            : (document.location.hostname.includes('.dev.')) ? CONTEXT_URL_REPORT.DEV
                : (document.location.hostname.includes('.at.')) ? CONTEXT_URL_REPORT.AT
                    : CONTEXT_URL_REPORT.PROD;
        const otherParams = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        };
        security.hasAnyRole([roles.dev, roles.admin]) && fetch(currentContext, otherParams).then(response => console.log(response));
    };

    useEffect(() => {
        setButtons();
    }, [appStore.currentOrg]);

    useEffect(() => {
        setButtons();
        wakeReportUp();
    }, []);

    return (
        <div key="home" className="home-wrapper">
            <h2>Welcome, {appStore.loggedInUser?.name || 'user'}</h2>
            <section className="home-actions">
                {menu.map(i => <Button
                    key={i.text}
                    label={i.text}
                    onClick={i.onClick}
                />)}
            </section>
        </div>
    );
};

export default observer(Home);
