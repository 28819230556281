import React, {useEffect, useState, useRef} from 'react';
import {
    PopupWindow,
    Button,
    ValidationStyle,
    InputList,
    validationEngineFromInputInformationList,
} from '@utahdts/dts-react-common';
import PropTypes from "prop-types";
import useRefLazy from "../../misc/hooks/useRefLazy";
import wacInputs from "../inputs/wacInputs";
import dates from "../../misc/isoDate";

const propTypes = {
    wac: PropTypes.object.isRequired,

    isNew: PropTypes.bool.isRequired,

    onClose: PropTypes.func.isRequired,

    onSave: PropTypes.func.isRequired,

    error: PropTypes.string
};
const defaultProps = {};

const numberKeys = ["increaseAmount", "rdCosts", "wacAfterIncrease", "historyOneYear", "historyTwoYear"];

const Wac = ({wac, isNew, onClose, onSave, error}) => {
    const [isBusy, setBusy] = useState(false);

    const [inputValues, setInputValues] = useState(() => (Object.assign({
        id: null,
        ndc: null,
        effectiveDate: null,
        increaseAmount: null,
        wacAfterIncrease: null,
        historyOneYear: null,
        historyTwoYear: null,
        increaseFactors: null,
        rdCosts: null,
        comments: null,
        drugDescription: null
    }, wac)));
    const inputValuesRef = useRef();
    inputValuesRef.current = inputValues;

    const [validationEngineStyle, setValidationEngineStyle] = useState(ValidationStyle.BLURRED);
    const validationEngineRef = useRef(undefined);

    const [validationEngine, setValidationEngine] = useState(undefined);
    validationEngineRef.current = validationEngine;
    if (validationEngine) {
        validationEngine.validationStyle = validationEngineStyle;
    }

    const inputs = useRefLazy(() => wacInputs({
        inputsOnChange: (field, value) => setInputValues(oldValues => ({ ...oldValues, [field]: value })),
        drugTypeChange: (field, value) => {
            setInputValues(oldValues => ({ ...oldValues, [field]: value }));
        },
    }, isNew, inputValues));

    const submit = () => {
        setValidationEngineStyle(ValidationStyle.ALWAYS);
        if (validationEngineRef.current && validationEngineRef.current.isValid(inputValuesRef.current)) {
            setBusy(true);
            while (inputValues.ndc.length < 11) {
                inputValues.ndc = '0' + inputValues.ndc;
            }
            inputValues.effectiveDate = dates.convert(inputValues.effectiveDate);
            //inputValues.patentExpirationDate = dates.convert(inputValues.patentExpirationDate);
            numberKeys.forEach(k => inputValues[k] = inputValues[k] ? inputValues[k].toString() : '0');
            onSave(inputValues).catch(() => setBusy(false));
        }
    };

    useEffect(() => {
        setValidationEngine(validationEngineFromInputInformationList(inputs.current));
    }, [inputs.current]);

    useEffect(() => {
        numberKeys.forEach(k => wac[k] = wac[k] ? wac[k].toString() : '0');
        setInputValues(wac);
    }, [])

    return (
        <PopupWindow
            className="popup-window--large"
            onCloseButtonCallback={onClose}
            footerChildren={[
                <Button label="Close" onClick={onClose} key="close-button" className="button--black"/>,
                <Button label={isNew ? "Save" : "Okay"} busy={isBusy} onClick={isNew ? submit : onClose} key="save-button"/>
            ]}>
            <h3>{inputValues.id ? 'View WAC' : 'Add new WAC'}</h3>
            <InputList inputs={Object.values(inputs.current)} data={inputValues} validationEngine={validationEngine} className="stack-form flex flex-wrap half-form" />
            {error && <div className="form-error">{error}</div>}
        </PopupWindow>
    )
};

Wac.propTypes = propTypes;
Wac.defaultProps = defaultProps;

export default Wac;
