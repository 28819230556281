import quoteGQLString from "../../util/quoteGQLString";

export default user => `
    id: ${quoteGQLString(user?.id)}
    name: ${quoteGQLString(user?.name)}
    email: ${quoteGQLString(user?.email)}
    phone: ${quoteGQLString(user?.phone)}
    submitOrg: ${quoteGQLString(user?.submitOrg)}
    orgs: [${(user?.orgs || []).map(role => quoteGQLString(role)).join(' ')}]
    roles: [${(user?.roles || []).map(role => (
    `{
            role: ${quoteGQLString(role.role)}
            org: ${quoteGQLString(role.org)}
            primaryContact: ${!!role.primaryContact}
            alternativeContact: ${!!role.alternativeContact}
        }`
)).join(' ')}]
`