import React, {useEffect} from 'react';
import UtahHeaderConfig from "./UtahHeaderConfig";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import useUtahHeader from "./useUtahHeader";
import appStore from "../mobx/appStore";
import menuStore from "../mobx/menuStore";

const propTypes = {
    utahHeaderVersion: PropTypes.string,
    utahHeaderUrl: PropTypes.string,
};
const defaultProps = {
    utahHeaderUrl: null,
    utahHeaderVersion: 'latest',
};

const UtahHeader = (
    {
        utahHeaderVersion,
        utahHeaderUrl
    }
) => {
    const utahHeader = useUtahHeader({utahHeaderVersion, utahHeaderUrl});

    // reload menu in case there were changes; always run this, but run outside render
    useEffect(() => {
        if (utahHeader) {
            utahHeader.setConfig(UtahHeaderConfig(utahHeader));
            utahHeader.setMainMenu(menuStore.mainMenu);

            if (appStore.loggedInUser) {
                const [firstName, ...lastName] = (appStore.loggedInUser.name || '').split(' ');
                utahHeader.showUserAsSignedIn({
                    first: firstName,
                    last: lastName.join(' '),
                });
            } else {
                utahHeader.showSignIn();
            }
        }
    }, [utahHeader, appStore.loggedInUser, appStore.currentOrg]);

    return null;
};

UtahHeader.propTypes = propTypes;
UtahHeader.defaultProps = defaultProps;

export default observer(UtahHeader);
