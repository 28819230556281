import {Components, InputInformation, ValidationType, Size, RegexLib} from "@utahdts/dts-react-common";

export default ({inputsOnChange}) => {
    const inputs = {
        ndc: new InputInformation({
            field: 'startdate',
            label: 'Start date:',
            type: Components.CALENDAR_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            maskType: {
                inputMask: new Inputmask('datetime', {
                    inputFormat: 'mm/dd/yyyy',
                    placeholder: "MM/DD/YYYY",
                    clearMaskOnLostFocus: false,
                    onBeforePaste: value => {
                        value.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/) && inputsOnChange('startdate', value);
                    }
                }),
                validation: {
                    validationRegExp: RegexLib.DATE,
                    validationErrorMessage: data => `${data} does not match MM/DD/YYYY`,
                },
                className: "mask-date"
            },
        }),
        fdaApprovalDate: new InputInformation({
            field: 'enddate',
            label: 'End date:',
            type: Components.CALENDAR_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            maskType: {
                inputMask: new Inputmask('datetime', {
                    inputFormat: 'mm/dd/yyyy',
                    placeholder: "MM/DD/YYYY",
                    clearMaskOnLostFocus: false,
                    onBeforePaste: value => {
                        value.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/) && inputsOnChange('enddate', value);
                    }
                }),
                validation: {
                    validationRegExp: RegexLib.DATE,
                    validationErrorMessage: data => `${data} does not match MM/DD/YYYY`,
                },
                className: "mask-date"
            },
        }),
    };

    Object.values(inputs).forEach(input => input.onChange = inputsOnChange);

    return inputs;
}