import {Components, InputInformation, ValidationType, Size, RegexLib} from "@utahdts/dts-react-common";
import Inputmask from "inputmask";

export default ({inputsOnChange, drugTypeChange}, isNew, inputValues) => {
    const inputs = {
        ndc: new InputInformation({
            field: 'ndc',
            label: 'National Drug Code:',
            type: Components.TEXT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
            maskType: {
                inputMask: new Inputmask('99999999999', {
                    placeholder: '',
                    clearMaskOnLostFocus: false,
                    autoUnmask: true,
                    onBeforePaste: value => {
                        value.match(/\d+/g) && inputsOnChange('ndc', value.match(/\d+/g).join(''));
                    }
                }),
            },
        }),
        drugDescription: new InputInformation({
            field: 'drugDescription',
            label: 'Drug description',
            type: Components.TEXT_AREA,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
        drugType: new InputInformation({
            field: 'drugType',
            label: 'Drug Type:',
            type: Components.SELECT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
            options: [
                {label: 'Brand', value: 'Brand'},
                {label: 'Generic', value: 'Generic'},
            ],
            multiSelect: false,
        }),
        effectiveDate: new InputInformation({
            field: 'effectiveDate',
            label: 'Effective Date:',
            type: Components.CALENDAR_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
            maskType: {
                inputMask: new Inputmask('datetime', {
                    inputFormat: 'mm/dd/yyyy',
                    placeholder: "MM/DD/YYYY",
                    clearMaskOnLostFocus: false,
                    onBeforePaste: value => {
                        value.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/) && inputsOnChange('effectiveDate', value);
                    }
                }),
                validation: {
                    validationRegExp: RegexLib.DATE,
                    validationErrorMessage: data => `${data} does not match MM/DD/YYYY`,
                },
                className: "mask-date"
            },
        }),
        increaseAmount: new InputInformation({
            field: 'increaseAmount',
            label: 'Increase Amount:',
            type: Components.TEXT_INPUT,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
        wacAfterIncrease: new InputInformation({
            field: 'wacAfterIncrease',
            label: 'WAC After Increase:',
            type: Components.TEXT_INPUT,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
        historyOneYear: new InputInformation({
            field: 'historyOneYear',
            label: 'History One Year:',
            type: Components.TEXT_INPUT,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
        historyTwoYear: new InputInformation({
            field: 'historyTwoYear',
            label: 'History Two Year:',
            type: Components.TEXT_INPUT,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
        increaseFactors: new InputInformation({
            field: 'increaseFactors',
            label: 'Increase Factors:',
            type: Components.TEXT_AREA,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
        rdCosts: new InputInformation({
            field: 'rdCosts',
            label: 'R&D Costs:',
            type: Components.TEXT_INPUT,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
        comments: new InputInformation({
            field: 'comments',
            label: 'Comments:',
            type: Components.TEXT_AREA,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
        }),
    };

    Object.values(inputs).forEach(input => input.onChange = inputsOnChange);
    inputs.drugType.onChange = drugTypeChange;

    return inputs;
}
