import {makeAutoObservable} from "mobx";
import Pages from "../pages/Pages";
import appStore from "./appStore";
import {OIDCOptions} from "@utahdts/dts-react-common";
import Icon from "../../misc/Icon";
import { renderToStaticMarkup } from 'react-dom/server'
import roles from "../roles/roles";
import security from "../roles/security";

const defaultMenu = {
    report: {
        text: 'Report',
        iconType: 'string',
        icon: renderToStaticMarkup(Icon.iconDownload()),
        roles: [roles.admin, roles.dev],
        onClick: Pages.report.forward,
    },
    listings: {
        text: 'Drug Listings',
        iconType: 'string',
        icon: renderToStaticMarkup(Icon.iconCards()),
        roles: [roles.admin, roles.dev, roles.orgAdmin, roles.user],
        onClick: Pages.listings.forward,
    },
    wacs: {
        text: 'WAC History',
        iconType: 'string',
        icon: renderToStaticMarkup(Icon.iconGraph()),
        roles: [roles.admin, roles.dev, roles.orgAdmin, roles.user],
        onClick: Pages.wacs.forward,
    },
    approvals: {
        text: 'Approval History',
        iconType: 'string',
        icon: renderToStaticMarkup(Icon.iconCheckbox()),
        roles: [roles.admin, roles.dev, roles.orgAdmin, roles.user],
        onClick: Pages.approvals.forward,
    },
    patents: {
        text: 'Patent History',
        iconType: 'string',
        icon: renderToStaticMarkup(Icon.iconStar()),
        roles: [roles.admin, roles.dev, roles.orgAdmin, roles.user],
        onClick: Pages.patents.forward,
    },
    users: {
        text: 'Users',
        iconType: 'string',
        icon: renderToStaticMarkup(Icon.iconUsers()),
        roles: [roles.admin, roles.dev, roles.orgAdmin],
        onClick: Pages.users.forward,
    },
    companies: {
        text: 'Companies',
        iconType: 'string',
        icon: renderToStaticMarkup(Icon.iconSuitcase()),
        roles: [roles.admin, roles.dev],
        onClick: Pages.companies.forward,
    },
    company: {
        text: 'Company',
        iconType: 'string',
        icon: renderToStaticMarkup(Icon.iconSuitcase()),
        roles: [roles.orgAdmin],
        onClick: Pages.singleCompany.forward,
    },
    switch: {
      text: 'Switch Company',
      onClick: () => appStore.showMessage = true
    },
    signOut: {
        onClick: () => {
            appStore.signOut();
            window.location = OIDCOptions.paths.logout();
        },
        text: 'Sign Out',
    }
};

export default new class {
    mainMenu = [];

    constructor() {
        makeAutoObservable(this);
        this.setupMenu();
    }

    setupMenu = () => (
        this.mainMenu = Object.keys(defaultMenu)
            .filter(menuKey => {
                let allow = true;

                if(defaultMenu[menuKey].roles) {
                    allow = security.hasAnyRole(defaultMenu[menuKey].roles);
                }

                if(menuKey === 'switch') {
                    allow = !security.hasAnyRole([roles.dev, roles.admin]) &&
                        appStore.loggedInUser?.roles ? [...new Set(appStore.loggedInUser?.roles?.map(role => role.org))].length > 1 : false;
                }

                return allow;
            })
            .map(menuKey => defaultMenu[menuKey])
    );
}();
