export default () => `
    id
    email
    name
    phone
    roles {
        org
        role
        primaryContact
        alternativeContact
    }
    orgs
`;
