import React from 'react';
import {
    ColumnInformation,
    joinClassNames,
    Button,
    TableSorterField,
    TableSorterInformation, PopOver,
} from "@utahdts/dts-react-common";
import Icon from "../../misc/Icon";
import TableSorterSortType from "@utahdts/dts-react-common/components/table-sorter/TableSorterSortType";
import TableSorterDirection from "@utahdts/dts-react-common/components/table-sorter/TableSorterDirection";

const customTd = (columnInfo, data, editUser, deleteUser) => {
    return (
        <td key={joinClassNames(columnInfo.field, data.name)}>
            <PopOver
                anchor={(
                    <Button
                        label=""
                        children={Icon.iconEllipsis()}
                        className="button--naked ellipsis"
                        onClick={() => false}
                    />
                )}
                dismissible={true}
                mode={PopOver.MODE.CLICK}
                position={PopOver.POSITION.BOTTOM}
            >
                <ul className="actions">
                    <li className="actions-item" onClick={() => {
                        data.roles = data.roles ? data.roles : [];
                        editUser(false, data, true)
                    }}>{Icon.iconPencil()} Edit User</li>
                    <li className="actions-item" onClick={() => deleteUser(data)}>{Icon.iconTrash()} Delete User</li>
                </ul>
            </PopOver>
        </td>
    )
};

const rolesTd = (columnInfo, data) => {
    return (
        <td key={joinClassNames(columnInfo.field, data.name)}>
            {data[columnInfo.field] && data[columnInfo.field].map((role, i) => ( (i ? ', ': '') + role.role))}
        </td>
    )
};

const sorters = {
    name: new TableSorterField("name", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    email: new TableSorterField("email", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    phone: new TableSorterField("phone", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    roles: new TableSorterField("roles", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
};

const defaultSorters = firstSorter => new TableSorterInformation([
    firstSorter,
    ...(Object.values(sorters).filter(sorter => sorter !== firstSorter)),
]);

export default (editUser, deleteUser) => [
    new ColumnInformation({title: 'Name', field: 'name', tableSorterInformation: defaultSorters(sorters.name)}),
    new ColumnInformation({title: 'Email', field: 'email', tableSorterInformation: defaultSorters(sorters.email)}),
    new ColumnInformation({title: 'Phone', field: 'phone', tableSorterInformation: defaultSorters(sorters.phone)}),
    new ColumnInformation({title: 'Roles', field: 'roles', tableSorterInformation: defaultSorters(sorters.roles), tdCallback: rolesTd, filter: false }),
    new ColumnInformation({title: 'Actions', field: 'actions', tdCallback: (columnInfo, data) => customTd(columnInfo, data, editUser, deleteUser), filter: false}),
];