import React, {useEffect, useState, useRef} from 'react';
import {
    Button,
    ValidationStyle,
    InputList,
    validationEngineFromInputInformationList, MessagePopupCore,
} from '@utahdts/dts-react-common';
import PropTypes from "prop-types";
import useRefLazy from "../misc/hooks/useRefLazy";
import companyInputs from "./inputs/companyInputs";
import webservice from "../app/webservice/webservice";
import userInputs from "./inputs/userInputs";
import roles from "../app/roles/roles";

const propTypes = {};
const defaultProps = {};

const Registration = () => {
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(undefined);
    const [nextPage, setNextPage] = useState(false);
    const [inputValues, setInputValues] = useState({
        id: null,
        name: null,
        fein: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
    });
    const inputValuesRef = useRef();
    inputValuesRef.current = inputValues;

    const [validationEngineStyle, setValidationEngineStyle] = useState(ValidationStyle.BLURRED);
    const validationEngineRef = useRef(undefined);

    const inputs = useRefLazy(() => companyInputs({
        inputsOnChange: (field, value) => setInputValues(oldValues => ({ ...oldValues, [field]: value }))
    }, true));

    const [validationEngine, setValidationEngine] = useState(undefined);
    validationEngineRef.current = validationEngine;
    if (validationEngine) {
        validationEngine.validationStyle = validationEngineStyle;
    }

    const checkCompany = () => {
        setValidationEngineStyle(ValidationStyle.ALWAYS);
        if (validationEngineRef.current && validationEngineRef.current.isValid(inputValuesRef.current)) {
            setNextPage(true);
        }
    };

    const [contactValues, setContactValues] = useState({
        name: null,
        email: null,
        phone: null,
    });
    const contactValuesRef = useRef();
    contactValuesRef.current = contactValues;

    const [contactValidationStyle, setContactValidationStyle] = useState(ValidationStyle.BLURRED);
    const contactValidationRef = useRef(undefined);

    const contactInputs = useRefLazy(() => userInputs({
        inputsOnChange: (field, value) => setContactValues(oldValues => ({ ...oldValues, [field]: value }))
    }, true, true));

    const [contactValidation, setContactValidation] = useState(undefined);
    contactValidationRef.current = contactValidation;
    if (contactValidation) {
        contactValidation.validationStyle = contactValidationStyle;
    }

    const [alternateValues, setAlternateValues] = useState({
        name: null,
        email: null,
        phone: null,
    });
    const alternateValuesRef = useRef();
    alternateValuesRef.current = alternateValues;

    const [alternateValidationStyle, setAlternateValidationStyle] = useState(ValidationStyle.BLURRED);
    const alternateValidationRef = useRef(undefined);

    const alternateInputs = useRefLazy(() => userInputs({
        inputsOnChange: (field, value) => setAlternateValues(oldValues => ({ ...oldValues, [field]: value }))
    }, true, false));

    const [alternateValidation, setAlternateValidation] = useState(undefined);
    alternateValidationRef.current = alternateValidation;
    if (alternateValidation) {
        alternateValidation.validationStyle = alternateValidationStyle;
    }

    const submit = () => {
        setContactValidationStyle(ValidationStyle.ALWAYS);
        setAlternateValidationStyle(ValidationStyle.ALWAYS);
        if (contactValidationRef.current
            && contactValidationRef.current.isValid(contactValuesRef.current)
            && alternateValidationRef.current.isValid(alternateValuesRef.current)) {
            setBusy(true);
            webservice.company.submit(inputValues).then(data => {
                let queries = [];
                const org = data.submitOrg;
                let primary = setUser(contactValues, org, true);
                let alternative = alternateValues.email && setUser(alternateValues, org, false);
                queries.push(webservice.user.submit(primary).catch(displayUserError));
                alternative && queries.push(webservice.user.submit(alternative).catch(displayUserError));
                Promise.all(queries).then(displaySuccess);
            }).catch(displayCompanyError);
        }
    };

    const setUser = (inputs, org, isPrimary) => {
        return {
            name: inputs.name,
            email: inputs.email,
            phone: inputs.phone,
            orgs: [org.id],
            submitOrg: org.id,
            roles: [{
                role: roles.orgAdmin,
                org: org.id,
                primaryContact: isPrimary,
                alternativeContact: !isPrimary
            }]
        };
    };

    const displaySuccess = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Registration successful',
            message: <p>The company has been registered. <br/>
            The Utah Insurance Department will notify you once the company has been reviewed and approved.</p>
        });
        setNextPage(false);
        setContactValues({
            name: null,
            email: null,
            phone: null,
        });
        setInputValues({
            id: null,
            name: null,
            fein: null,
            address: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
        });
    };

    const displayCompanyError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred while registering the company. Please try again later or contact UID at uid.healthresearch@utah.gov.'
        })
    };

    const displayUserError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred while registering users. Please try again later or contact UID at uid.healthresearch@utah.gov.'
        })
    };

    useEffect(() => {
        setValidationEngine(validationEngineFromInputInformationList(inputs.current));
        setContactValidation(validationEngineFromInputInformationList(contactInputs.current));
        setAlternateValidation(validationEngineFromInputInformationList(alternateInputs.current));
    }, [inputs.current]);

    return (
        <div className="registration--container">
            <h3>Registration</h3>
            <p className="mb-zero text-standard">In order to access the system, a company needs to be registered. Please fill out the form below.<br/>
                The Utah Department of Insurance will then validate your account.</p>
            <div className={nextPage ? 'hidden' : ''}>
                <h4 className="mb-spacing">Company Info</h4>
                <InputList inputs={Object.values(inputs.current)} data={inputValues} validationEngine={validationEngine} className="stack-form flex flex-wrap half-form"/>
                <Button label="Next" busy={isBusy} onClick={checkCompany}/>
            </div>
            <div className={nextPage ? '' : 'hidden'}>
                <h4 className="mb-spacing">Primary Contact</h4>
                <InputList inputs={Object.values(contactInputs.current)} data={contactValues} validationEngine={contactValidation} className="stack-form flex flex-wrap half-form" />
                <h4 className="mb-spacing">Alternate Contact</h4>
                <InputList inputs={Object.values(alternateInputs.current)} data={alternateValues} validationEngine={alternateValidation} className="stack-form flex flex-wrap half-form" />
                <Button label="Back" busy={isBusy} onClick={() => setNextPage(false)} className="button--black mr-spacing--large"/>
                <Button label="Submit" busy={isBusy} onClick={submit}/>
            </div>
            {error && <div className="form-error">{error}</div>}
        </div>
    )
};

Registration.propTypes = propTypes;
Registration.defaultProps = defaultProps;

export default Registration;