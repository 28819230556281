import React, {useEffect} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import appStore from "../mobx/appStore";
import webservice from "../webservice/webservice";
import LocalStorage from "../localStorage/LocalStorage";
import menuStore from "../mobx/menuStore";
import {MessagePopupCore} from "@utahdts/dts-react-common";
import Pages from "../pages/Pages";

/*
This is a temp class until OidcCore can be refactored
to not use Redux. This takes the user from Redux and puts
it in to MobX when changes occur. This is super screwy, so
apologies are extended profusely. It means that when OIDC
does work and stores it in Redux, redux then triggers this
ReduxToMobxShim component to rerender which captures the change
and stuffs the change in to MobX which then causes the rest of
the app to rerender. The future intent (issue #27) is to remove
this class and create a new OIDCCore that has callbacks instead
of Redux so it can be used anywhere.
 */
const propTypes = {
    children: PropTypes.any,
    oidc: PropTypes.shape({
        user: PropTypes.shape({
            access_token: PropTypes.string,
        })
    })
};
const defaultProps = {
    children: undefined,
    oidc: undefined,
};
const mapStateToProps = state => ({oidc: state.oidc});

const setUtahHeaderLoggedInUser = userProfile => {
    LocalStorage.loggedInUser.set(userProfile);
    appStore.loggedInUser = userProfile;
    menuStore.setupMenu();
};

const ReduxToMobXShim = ({children, oidc}) => {
    useEffect(() => {
        // because site bounces to login and back, don't clear loggedInUser except on signout
        appStore.loggedInUser && setUtahHeaderLoggedInUser(appStore.loggedInUser);
        appStore.oidcUser = oidc?.user || appStore.oidcUser;

        // because page bounces to login and back, don't clear oidc user except on signout
        appStore.oidcUser && LocalStorage.oidcUser.set(appStore.oidcUser);

        if (appStore.oidcUser && (!appStore.loggedInUser || appStore.loggedInUser.loadedFromStorage)) {
            webservice.user.loggedInUser().then(setUtahHeaderLoggedInUser).catch(() => MessagePopupCore.addMessage({
                title: 'Something went wrong',
                message: 'No user were found.',
                closeCallback: () => Pages.home.forward()
            }));
        }
    }, [oidc, oidc?.user, oidc?.user?.access_token]);

    return children;
};

ReduxToMobXShim.propTypes = propTypes;
ReduxToMobXShim.defaultProps = defaultProps;

export default connect(mapStateToProps)(ReduxToMobXShim);
