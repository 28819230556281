import {Components, InputInformation, ValidationType, Size, MaskType} from "@utahdts/dts-react-common";

export default ({inputsOnChange}, isNew, isPrimary) => {
    const inputs = {
        name: new InputInformation({
            field: 'name',
            label: 'Name:',
            type: Components.TEXT_INPUT,
            validationTypes: [],
            size: Size.FULL_WIDTH,
        }),
        email: new InputInformation({
            field: 'email',
            label: 'Email',
            type: Components.TEXT_INPUT,
            validationTypes: [ValidationType.IS_EMAIL],
            size: Size.FULL_WIDTH,
        }),
        phone: new InputInformation({
            field: 'phone',
            label: 'Phone:',
            type: Components.TEXT_INPUT,
            validationTypes: [],
            size: Size.FULL_WIDTH,
            maskType: MaskType.PHONE
        }),
    };

    Object.values(inputs).forEach(input => {
        isPrimary && input.validationTypes.push(ValidationType.REQUIRED);
        input.onChange = inputsOnChange;
    });

    return inputs;
}