import React from 'react';
import Helmet from "react-helmet";
import appStore from "./mobx/appStore";
import {observer} from "mobx-react";

const propTypes = {};
const defaultProps = {};

const HeadTags = () => (
    <Helmet>
        <title>Utah Drug Transparency {appStore.pageTitle ? ` - ${appStore.pageTitle}` : ''}</title>
    </Helmet>
);

HeadTags.propTypes = propTypes;
HeadTags.defaultProps = defaultProps;

export default observer(HeadTags);
