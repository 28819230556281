import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import webservice from "../app/webservice/webservice";
import {Table, Button, MessagePopupCore} from "@utahdts/dts-react-common";
import columns from "./columns/ListingsColumns"
import Listing from "./modals/Listing";
import appStore from "../app/mobx/appStore";
import security from "../app/roles/security";
import roles from "../app/roles/roles";
import {usePrevious} from "../misc/hooks/usePrevious";

const Listings = () => {
    const [isBusy, setBusy] = useState(true);
    const [drugs, setDrugs] = useState([]);
    const [showDrug, displayDrug] = useState(false);
    const [drug, setDrug] = useState({});
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState(undefined);
    const prevOrg = usePrevious(appStore.currentOrg);

    const displayError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred. Please try again later.'
        })
    };

    const editDrug = (isNew, drug, showDrug) => {
        setIsNew(isNew);
        setDrug(drug);
        displayDrug(showDrug);
    };

    const resetDrug = () => {
        editDrug(true, {}, false);
        setError(undefined);
    };

    const saveDrug = drug => {
        if(isNew) {
            drug.org = security.hasAnyRole([roles.user, roles.orgAdmin]) ? appStore.loggedInUser.org : "0";
        }
        webservice.drug.save(drug).then(() => {
            setBusy(true);
            resetDrug();
            getDrugs();
        }).catch(displayError);
    };

    const getDrugs = () => {
        let orgID = security.hasAnyRole([roles.dev, roles.admin]) ? "" : appStore.currentOrg ? appStore.currentOrg.id : "not found";
        webservice.drug.all(orgID).then(data => {
            setDrugs(data.drugs);
            setBusy(false);
        }).catch(displayError);
    };

    useEffect(() => {
        if(prevOrg !== appStore.currentOrg) {
            setBusy(true);
            getDrugs()
        }
    }, [appStore.currentOrg]);

    useEffect(() => {
        getDrugs();
    }, []);

    return (
    <div key="listings" className="home-wrapper">
        <div className="home-content">
            <h1 className="mb-zero">Drug Listings</h1>
        </div>
        <div>
            <Table
                list={drugs}
                columns={columns(editDrug)}
                filters={true}
                showLimit={50}
                ajaxSpinner={isBusy}
            />
        </div>
        {showDrug && <Listing
            onClose={resetDrug}
            onSave={saveDrug}
            isNew={isNew}
            drug={drug}
        />}
    </div>
)};

export default observer(Listings);
