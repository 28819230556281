import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Button, Table, MessagePopupCore} from "@utahdts/dts-react-common";
import appStore from "../app/mobx/appStore";
import columns from "./columns/ApprovalColumns"
import webservice from "../app/webservice/webservice";
import Approval from "./modals/Approval";
import security from "../app/roles/security";
import roles from "../app/roles/roles";
import {usePrevious} from "../misc/hooks/usePrevious";

const Approvals = () => {
    const [isBusy, setBusy] = useState(true);
    const [approvals, setApprovals] = useState([]);
    const [showApproval, displayApproval] = useState(false);
    const [approval, setApproval] = useState({});
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState(undefined);
    const prevOrg = usePrevious(appStore.currentOrg);

    const displayError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred. Please try again later.'
        })
    };

    const editApproval = (isNew, approval, showApproval) => {
        setIsNew(isNew);
        let orgID = security.hasAnyRole([roles.dev, roles.admin]) ? "" : appStore.currentOrg ? appStore.currentOrg.id : undefined;
        approval.org = approval.org ? approval.org : orgID;
        setApproval(approval);
        displayApproval(showApproval);
    };

    const resetApproval = () => {
        editApproval(true, {}, false);
        setError(undefined);
    };

    const saveApproval = approval => webservice.wac.saveApproval(approval).then(() => {
        setBusy(true);
        resetApproval();
        getApprovals();
    }).catch(displayError);

    const getApprovals = () => {
        let orgID = appStore.currentOrg?.id || (security.hasAnyRole([roles.dev, roles.admin]) ?  null : "not found");
        webservice.wac.drugApprovalPerOrg(orgID).then(data => {
            setApprovals(data.drugApprovalByOrg);
            setBusy(false);
        }).catch(displayError);
    };

    useEffect(() => {
        if(prevOrg !== appStore.currentOrg) {
            setBusy(true);
            getApprovals();
        }
    }, [appStore.currentOrg]);

    useEffect(() => {
        getApprovals();
    }, []);

    return (
        <div key="listings" className="home-wrapper">
            <div className="home-content">
                <h1 className="mb-zero">Approval History</h1>
            </div>
            <div className="buttons flex">
                <Button
                    label="Manually Enter History"
                    className="mr-spacing secondary"
                    onClick={() => editApproval(true, {}, true)}
                />
            </div>
            <div>
                <Table
                    list={approvals}
                    columns={columns(editApproval)}
                    filters={true}
                    showLimit={50}
                    ajaxSpinner={isBusy}
                />
            </div>
            {showApproval && <Approval
                onClose={resetApproval}
                onSave={saveApproval}
                isNew={isNew}
                approval={approval}
            />}
        </div>
    )
};

export default observer(Approvals);
