import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {Button, SelectInput, Size} from "@utahdts/dts-react-common";
import roles from "../app/roles/roles";
import limitedRoles from "../app/roles/limitedRoles";
import Icon from "../misc/Icon";
import security from "../app/roles/security";
import appStore from "../app/mobx/appStore";

const propTypes = {
    role: PropTypes.object.isRequired,

    orgs: PropTypes.array,

    index: PropTypes.number.isRequired,

    deleteRole: PropTypes.func.isRequired,
};
const defaultProps = {
    orgs: []
};

const Role = ({role, orgs, index, deleteRole, onChange, error}) => {
    const checkRole = () => {
        let className = "";
        if(appStore.currentOrg
            && role.org !== null
            && security.hasAnyRole([roles.orgAdmin])
            && !security.hasAnyRole([roles.dev, roles.admin])){
            className = role.org !== appStore.currentOrg.id ? "hidden" : className;
        }
        return className;
    };

    return (
        <div className={checkRole()}>
            <SelectInput
                showLabel={false}
                label="Role"
                field='role'
                value={role.role}
                onChange={(field, value) => onChange(field, value, index)}
                size={Size.FULL_WIDTH}
                options={Object.keys(security.hasAnyRole([roles.dev, roles.admin]) ? roles : limitedRoles).map(role => ({label: roles[role], value: roles[role]}))}
                placeholder="Select a role..."
            />
            <SelectInput
                showLabel={false}
                label="Org"
                field='org'
                value={role.org}
                onChange={(field, value) => onChange(field, value, index)}
                size={Size.FULL_WIDTH}
                options={orgs}
                placeholder="Select a company..."
            />
            {role.error && <div className="form-error mb-spacing">{role.error}</div>}
            <Button
                label="Remove Role"
                className="flex align-items-center button--short mb-spacing float-right"
                onClick={() => deleteRole(index)}
            />
        </div>
    )
};

Role.propTypes = propTypes;
Role.defaultProps = defaultProps;

export default Role;