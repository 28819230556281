import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Button, PopupWindow, Table, MessagePopupCore} from "@utahdts/dts-react-common";
import Icon from "../misc/Icon";
import columns from "./columns/CompanyColumns"
import webservice from "../app/webservice/webservice";
import Company from "./modals/Company";
import roles from "../app/roles/roles";

const Companies = () => {
    const [isBusy, setBusy] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [showCompany, displayCompany] = useState(false);
    const [showDelete, displayDelete] = useState(false);
    const [showApproval, displayApproval] = useState(false);
    const [company, setCompany] = useState({});
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState(undefined);

    const displayError = () => {
        setBusy(false);
        MessagePopupCore.addMessage({
            title: 'Something went wrong',
            message: 'An error occurred. Please try again later.'
        })
    };

    const editCompany = (isNew, company, showCompany) => {
        setIsNew(isNew);
        setCompany(company);
        displayCompany(showCompany);
    };

    const inactivateCompany = company => {
        setCompany(company);
        displayDelete(true);
    };

    const approveCompany = company => {
        setCompany(company);
        displayApproval(true);
    };

    const resetCompany = refresh => {
        editCompany(true, {}, false);
        setError(undefined);
        displayDelete(false);
        displayApproval(false);
        refresh && setBusy(true);
        refresh && getCompanies();
    };

    const saveCompany = (company, oldPrimary, oldAlt) => {
        let queries = [];
        company.primaryContact?.id && queries.push(checkContact(company, oldPrimary, "primaryContact"));
        company.alternativeContact?.id && queries.push(checkContact(company, oldAlt, "alternativeContact"));
        queries.push(webservice.company.save(company));
        return Promise.all(queries).then(() => {
            resetCompany(true);
        });
    };

    const checkContact = (company, oldContact, field) => {
        if(company[field]?.id !== oldContact?.id) {
            let fieldRole = oldContact?.roles.findIndex(role => role.org === company.id && role[field]);
            if(fieldRole >= 0){
                let userRole = oldContact.roles[fieldRole];
                userRole[field] = false;
                saveContact(oldContact);
            }
            return setContact(company[field], field);
        } else {
            return setContact(oldContact, field);
        }
    };

    const setContact = (user, field) => {
        let isOrgAdmin = user.roles.findIndex(role => role.org === company.id && role.role === roles.orgAdmin);
        let isField = user.roles.findIndex(role => role.org === company.id && role[field]);
        if(isOrgAdmin >= 0) {
            let userRoles = user.roles[isOrgAdmin];
            userRoles[field] = true;
            isField >= 0 && isOrgAdmin !== isField && user.roles.splice(isField, 1);
        } else if(isField >= 0) {
            user.roles[isField].role = roles.orgAdmin;
        } else {
            user.roles.push({
                org: company.id,
                role: roles.orgAdmin,
                [field]: true,
            })
        }
        return saveContact(user);
    };

    const saveContact = user => webservice.user.save(user);

    const actualInactivate = () => webservice.company.inactivate(company.id).then(() => {
        resetCompany(true);
    }).catch(displayError);

    const actualApproval = () => webservice.company.approve(company.id).then(() => {
        resetCompany(true);
    }).catch(displayError);

    const getCompanies = () => webservice.company.all().then(data => {
        setCompanies(data.orgs);
        setBusy(false);
    }).catch(displayError);

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <div key="listings" className="home-wrapper">
            <div className="home-content">
                <h1 className="mb-zero">Companies</h1>
            </div>
            <div className="buttons flex">
                <Button
                    label="Add Company"
                    className="mr-spacing flex align-items-center"
                    onClick={() => editCompany(true, {}, true)}
                    children={<span className="icon--round ml-spacing">{Icon.iconPlus()}</span>}
                    childrenPostion={Button.CHILDREN_POSITION.AFTER}
                />
            </div>
            <div>
                <Table
                    list={companies}
                    columns={columns(editCompany, inactivateCompany, approveCompany)}
                    filters={true}
                    showLimit={50}
                    ajaxSpinner={isBusy}
                />
            </div>
            {showCompany && <Company
                onClose={resetCompany}
                onSave={saveCompany}
                isNew={isNew}
                company={company}
            />}
            {showDelete && <PopupWindow
                className="popup-window--large"
                onCloseButtonCallback={() => resetCompany(false)}
                footerChildren={[
                    <Button label="Cancel" onClick={() => resetCompany(false)} key="close-button" className="button--black"/>,
                    <Button label="Inactivate" busy={isBusy} onClick={actualInactivate} key="save-button"/>
                ]}>
                <h3>Are you sure?</h3>
                <p>You are about to inactivate a company.</p>
            </PopupWindow>}
            {showApproval && <PopupWindow
                className="popup-window--large"
                onCloseButtonCallback={() => resetCompany(false)}
                footerChildren={[
                    <Button label="Cancel" onClick={() => resetCompany(false)} key="close-button" className="button--black"/>,
                    <Button label="Approve" busy={isBusy} onClick={actualApproval} key="save-button"/>
                ]}>
                <h3>Are you sure?</h3>
                <p>You are about to approve a company. Please review the company info before submitting.</p>
            </PopupWindow>}
        </div>
    )
};

export default observer(Companies);
