export default () => `
    id
    name
    fein
    address
    address2
    city
    state
    zip
    status
`