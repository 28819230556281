import React, {useEffect, useState} from 'react';
import {isEmpty, OIDCOptions, PopupWindow, SelectInput, Size} from "@utahdts/dts-react-common";
import Button from "@utahdts/dts-react-common/components/form/button/Button";
import appStore from "../app/mobx/appStore";
import webservice from "../app/webservice/webservice";
import isEqual from "lodash/isEqual";
import {usePrevious} from "./hooks/usePrevious";
import {useForceUpdate} from "./hooks/useForceUpdate";
import security from "../app/roles/security";
import roles from "../app/roles/roles";
import {autorun} from "mobx";
import {observer} from "mobx-react";
import {statuses} from "./Shared";
import menuStore from "../app/mobx/menuStore";

const CompanyPopup = () => {
    const [inputValue, setInputValue] = useState(appStore.currentOrg);
    const prevUser = usePrevious(appStore.loggedInUser);
    const forceUpdate = useForceUpdate();

    const onClosePopup = () => {
        appStore.currentOrg = inputValue;
        appStore.showMessage = false;
        menuStore.setupMenu();
        forceUpdate();
    };

    const onChange = (field, value) => {
        setInputValue(appStore.userOrgs.find(org => org.id === value))
    };

    const setOrg = () => {
        appStore.currentOrg = undefined;
        if(appStore.loggedInUser?.roles && appStore.loggedInUser.roles.length > 0) {
            let orgs = [...new Set(appStore.loggedInUser.roles.reduce((result, role) => {
                !isEmpty(role.org) && result.push(role.org);
                return result
            }, []))];
            if (orgs.length > 1) {
                if(orgs.length !== appStore.userOrgs.length) {
                    let options = [];
                    let queries = orgs.map(org => webservice.company.get(org)
                        .then(org => {
                            org.status === statuses.APPROVED && options.push({
                                ...org,
                                roles: appStore.loggedInUser.roles.filter(role => role.org === org.id).map(role => role.role),
                                label: org.name,
                                value: org.id
                            })
                        }));
                    Promise.all(queries).then(() => {
                        if(options.length > 1) {
                            appStore.userOrgs = options;
                            appStore.showMessage = true;
                            forceUpdate();
                        } else if(options.length === 1) {
                            appStore.currentOrg = options[0];
                            menuStore.setupMenu();
                        } else {
                            appStore.showMessage = true;
                            forceUpdate();
                        }
                    });
                } else {
                    appStore.showMessage = true;
                }
            } else {
                webservice.company.get(orgs[0]).then(org => {
                    if(org.status === statuses.APPROVED) {
                        appStore.currentOrg = {
                            ...org,
                            roles: appStore.loggedInUser.roles.filter(role => role.org === org.id).map(role => role.role),
                            label: org.name,
                            value: org.id
                        };
                    } else {
                        appStore.showMessage = true;
                    }
                });
            }
        } else {
            appStore.showMessage = true;
        }
    };

    useEffect(() => {
            if (!isEqual(prevUser?.roles, appStore.loggedInUser?.roles)) {
                !security.hasAnyRole([roles.dev, roles.admin]) && setOrg();
            }
        }, [appStore.loggedInUser]);

    useEffect(() =>
        autorun(() => {
            if (appStore.showMessage) forceUpdate()
        }),
    [appStore.showMessage]);

    return (appStore.showMessage && <PopupWindow
                keyName="CompanyPopupUnique"
                footerChildren={appStore.userOrgs.length > 0 ? [<Button
                    key={'blah'}
                    label="Okay"
                    disabled={!inputValue}
                    className="message-popup__button-okay"
                    onClick={() => onClosePopup()}/>] : [<Button
                    key={'blah'}
                    label="Okay"
                    className="message-popup__button-okay"
                    onClick={() => {
                        appStore.signOut();
                        window.location = OIDCOptions.paths.logout();
                    }}/>]}
                >
                <div className="atid_message_popup_content_wrapper message-popup__content-wrapper">
                    <div className="message-popup__message-wrapper flex1">
                        <h3>Session info</h3>
                        {appStore.userOrgs.length > 0 ? <>
                            <p>This account is associated with multiple companies. <br/>
                            Please select the company used for this session. <br/>
                            You can switch anytime in the menu.</p>
                            <SelectInput
                                showLabel={false}
                                label="Current company"
                                field='currentOrg'
                                value={inputValue}
                                onChange={onChange}
                                size={Size.FULL_WIDTH}
                                options={appStore.userOrgs}
                            />
                        </> : <p>This account is not associated with any approved company. <br/>
                                The Utah Insurance Department might be reviewing your registration. Please try again later.</p>}
                    </div>
                </div>
            </PopupWindow>)
};

export default observer(CompanyPopup);