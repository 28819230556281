import React from 'react';
import {
    Button,
    ColumnInformation, joinClassNames, PopOver,
    TableSorterField,
    TableSorterInformation,
} from "@utahdts/dts-react-common";
import TableSorterSortType from "@utahdts/dts-react-common/components/table-sorter/TableSorterSortType";
import TableSorterDirection from "@utahdts/dts-react-common/components/table-sorter/TableSorterDirection";
import Icon from "../../misc/Icon";
import {dateTd} from "../../misc/Shared";

const customTd = (columnInfo, data, editApproval) => {
    return (
        <td key={joinClassNames(columnInfo.field, data.name)}>
            <PopOver
                anchor={(
                    <Button
                        label=""
                        children={Icon.iconEllipsis()}
                        className="button--naked ellipsis"
                        onClick={() => false}
                    />
                )}
                dismissible={true}
                mode={PopOver.MODE.CLICK}
                position={PopOver.POSITION.BOTTOM}
            >
                <ul className="actions">
                    <li className="actions-item" onClick={() => editApproval(true, data, true)}>{Icon.iconEye()} See Approval</li>
                </ul>
            </PopOver>
        </td>
    )
};

const sorters = {
    fdaApprovalDate: new TableSorterField("fdaApprovalDate", TableSorterSortType.DATE, TableSorterDirection.ASCENDING),
    drugDescription: new TableSorterField("drugDescription", TableSorterSortType.TEXT, TableSorterDirection.ASCENDING),
    createdDate: new TableSorterField("createdDate", TableSorterSortType.DATE, TableSorterDirection.ASCENDING),
};

const defaultSorters = firstSorter => new TableSorterInformation([
    firstSorter,
    ...(Object.values(sorters).filter(sorter => sorter !== firstSorter)),
]);

export default editApproval => [
    new ColumnInformation({title: 'Drug Name', field: 'drugDescription', tableSorterInformation: defaultSorters(sorters.drugDescription)}),
    new ColumnInformation({title: 'Approval Date', field: 'fdaApprovalDate', tableSorterInformation: defaultSorters(sorters.fdaApprovalDate), tdCallback: (columnInfo, data) => dateTd(columnInfo, data, false)}),
    new ColumnInformation({title: 'Date Submitted', field: 'createdDate', tableSorterInformation: defaultSorters(sorters.createdDate), tdCallback: (columnInfo, data) => dateTd(columnInfo, data, true)}),
    new ColumnInformation({title: 'Actions', field: 'actions', tdCallback: (columnInfo, data) => customTd(columnInfo, data, editApproval), filter: false}),
];
