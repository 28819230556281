import {Components, InputInformation, ValidationType, Size, MaskType} from "@utahdts/dts-react-common";

export default ({inputsOnChange}, isNew, users) => {
    const inputs = {
        name: new InputInformation({
            field: 'name',
            label: 'Name:',
            type: Components.TEXT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,

        }),
        fein: new InputInformation({
            field: 'fein',
            label: 'FEIN',
            type: Components.TEXT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,

        }),
        address: new InputInformation({
            field: 'address',
            label: 'Address 1:',
            type: Components.TEXT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,

        }),
        address2: new InputInformation({
            field: 'address2',
            label: 'Address 2:',
            type: Components.TEXT_INPUT,
            size: Size.FULL_WIDTH,
            disabled: !isNew,

        }),
        city: new InputInformation({
            field: 'city',
            label: 'City:',
            type: Components.TEXT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,

        }),
        state: new InputInformation({
            field: 'state',
            label: 'State:',
            type: Components.TEXT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,

        }),
        zip: new InputInformation({
            field: 'zip',
            label: 'Zip:',
            type: Components.TEXT_INPUT,
            validationTypes: ValidationType.REQUIRED,
            size: Size.FULL_WIDTH,
            disabled: !isNew,
            maskType: MaskType.ZIPCODE
        }),
    };

    Object.values(inputs).forEach(input => input.onChange = inputsOnChange);

    return inputs;
}