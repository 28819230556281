import quoteGQLString from "../../util/quoteGQLString";

export default wac => `
    id: ${quoteGQLString(wac?.id)}
    ndc: ${quoteGQLString(wac?.ndc)}
    org: ${quoteGQLString(wac?.org)}
    effectiveDate: ${quoteGQLString(wac?.effectiveDate)}
    increaseAmount: ${quoteGQLString(wac?.increaseAmount)}
    wacAfterIncrease: ${wac?.wacAfterIncrease}
    historyOneYear: ${wac?.historyOneYear}
    historyTwoYear: ${wac?.historyTwoYear}
    increaseFactors: ${quoteGQLString(wac?.increaseFactors)}
    rdCosts: ${quoteGQLString(wac?.rdCosts)}
    comments: ${quoteGQLString(wac?.comments)}
    drugDescription: ${quoteGQLString(wac?.drugDescription)}
    drugType: ${quoteGQLString(wac?.drugType)}
`