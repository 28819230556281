import intersection from "lodash/intersection";
import castArray from "lodash/castArray";
import appStore from "../mobx/appStore";
import rolesList from "./roles";

const getRoles = () => {
    let roles = appStore.loggedInUser?.roles?.map(role => role.role);
    return roles && (roles.some(role => [rolesList.dev, rolesList.admin].includes(role)) || (appStore.loggedInUser?.orgs && appStore.loggedInUser?.orgs.length === 1))
        ? roles : appStore.currentOrg?.roles
            ? appStore.currentOrg?.roles : [];
};

const security = {
    hasAllRoles: roles => intersection(castArray(roles), getRoles()).length === castArray(roles).length,
    hasAnyRole: roles => getRoles().some(role => (roles || []).includes(role)),
    getRoles: getRoles
};

export default security;
